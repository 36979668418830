import React from "react";
import "../css/ContactPage.scss";
import { FormInputs } from "../components/FormInputs";
import ImgSh from "../components/Images/ImgSh";



const Contactpage = ({content}) => {
    // find the "Elementary" page
    const contactPage = content.pages.find((page) => page.id === "Contact");

    if (!contactPage) {
      return <div>"Contact" page not found.</div>;
    }

  return (
<div className="container text-center">
<a href="https://us13.list-manage.com/contact-form?u=582977b975260743dc2371fac&form_id=cf00b3e6438e296c68210cea89328888" target="_blank">

      <div className="contactImgContainer mobile-no-show">
        <ImgSh src={contactPage.images.contactImg} alt="" />
      </div>
      <ImgSh className="d-block mobile-show-only" src={contactPage.images.mobileImg} noPlaceholder={true} />
      <div className="contactHeader">
        <h1>{contactPage.headers.mainHeader}</h1>
        <h4>
          {contactPage.headers.subHeader}
        </h4>
        <button
          Text="Contact Us"
          linkto="https://us13.list-manage.com/contact-form?u=582977b975260743dc2371fac&form_id=cf00b3e6438e296c68210cea89328888"
          ></button>

      </div>

      {/* <FormInputs></FormInputs> */}

          </a>
      <div className="contactFooter">
        <h2>{contactPage.headers.footerHeader}</h2>
        <p className="contactHelpForm">Fill out <a href="https://us13.list-manage.com/contact-form?u=582977b975260743dc2371fac&form_id=cf00b3e6438e296c68210cea89328888" target="_blank">this</a> form if you need help or support on an existing product or service, and we’ll come back to you as soon as we can.</p>
        <br />
        {/* <div class="letter-image">
          <div class="animated-mail">
            <div class="back-fold"></div>
            <div class="letter">
              <div class="letter-border"></div>
              <div class="letter-title"></div>
              <div class="letter-context"></div>
              <div class="letter-stamp">
                <div class="letter-stamp-inner"></div>
              </div>
            </div>
            <div class="top-fold"></div>
            <div class="body"></div>
            <div class="left-fold"></div>
          </div>
          <div class="shadow"></div>
        </div> */}
        <br />
        <h4>Or, leave us a message the old-fashioned way:</h4>
        <p>
          {contactPage.contactInfo.email.text}{" "}
          <a href={contactPage.contactInfo.email.link}>
            {contactPage.contactInfo.email.display}
          </a>
        </p>
        {/* <br />
        <p>{contactPage.contactInfo.phone}</p>
        <br /> */}
        <p>
          {contactPage.contactInfo.address.map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
    </div>
  );
};

export default Contactpage;
