import React, { useState, useRef, useCallback, useMemo } from "react";
import { motion } from "framer-motion";
import "../css/slideshow.scss";
import { Modal } from "./Modal";
import { useTimer } from "./useTimer";

const transition = { duration: 0.5, ease: "easeInOut" };

const variants = {
  active: { zIndex: 2 },
  previous: { zIndex: 1 },
  next: { zIndex: -1 },
};

const Slideshow = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragDistance, setDragDistance] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [activeModalImageIndex, setActiveModalImageIndex] = useState(null);

  const startXRef = useRef(null);
  const touchContainerRef = useRef(null);

  const threshold = 100;
  const { startTimer, stopTimer } = useTimer(() => {
    setActiveIndex(
      (prevActiveIndex) => (prevActiveIndex + 1 + images.length) % images.length
    );
  }, 5000);

  const resetTimer = useCallback(() => {
    stopTimer();
    startTimer();
  }, [stopTimer, startTimer]);

  const nextImage = useCallback(() => {
    setActiveIndex(
      (prevActiveIndex) => (prevActiveIndex + 1 + images.length) % images.length
    );
    resetTimer();
  }, [images.length, resetTimer]);

  const previousImage = useCallback(() => {
    setActiveIndex(
      (prevActiveIndex) => (prevActiveIndex - 1 + images.length) % images.length
    );
    resetTimer();
  }, [images.length, resetTimer]);

  const handleDragStart = useCallback((clientX) => {
    setIsDragging(true);
    startXRef.current = clientX;
  }, []);

  const handleDragMove = useCallback(
    (clientX) => {
      if (!isDragging) return;
      const moveX = clientX - startXRef.current;
      setDragDistance(moveX);
    },
    [isDragging]
  );

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
    setDragDistance(0);

    if (dragDistance > threshold) {
      nextImage();
    } else if (dragDistance < -threshold) {
      previousImage();
    }
  }, [dragDistance, threshold, previousImage, nextImage]);

  const createDragHandler = (start, move, end) => {
    return {
      handleStart: (event) => handleDragStart(event[start]),
      handleMove: (event) => handleDragMove(event[move]),
      handleEnd: end,
    };
  };

  const mouseHandlers = createDragHandler("clientX", "clientX", handleDragEnd);
  const touchHandlers = createDragHandler(
    "touches[0].clientX",
    "touches[0].clientX",
    handleDragEnd
  );

  const handleButtonClick = useCallback(
    (imageIndex) => {
      if (imageIndex !== activeIndex) {
        setActiveIndex(imageIndex);
        resetTimer();
      }
    },
    [activeIndex, resetTimer]
  );

  const getImageClassName = (index) => {
    let className = "image";
    if (index === activeIndex) className += " active";
    if (index === (activeIndex - 1 + images.length) % images.length)
      className += " previous";
    if (index === (activeIndex + 1) % images.length) className += " next";
    return className;
  };

  const openModal = (imageIndex) => {
    setActiveModalImageIndex(imageIndex);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const imageElements = useMemo(
    () =>
      images.map((image, index) => (
        <div className="image-container" key={image.src}>
          <motion.img
            layout
            src={image.src}
            alt={image.subtitle}
            className={getImageClassName(index)}
            variants={variants}
            transition={transition}
            initial="active"
            animate={getImageClassName(index)}
          />
          {/* <button
            onClick={() => openModal(index)}
            className={`fullscreen-button ${
              index === activeIndex ? "active" : ""
            }`}
          ><p style={{lineHeight: "20px"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16">
  <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
</svg> Fullscreen</p>
          </button> */}
        </div>
      )),
    [images, activeIndex]
  );

  return (
    <>
      {showModal && (
        <div className="modal-container">
          <Modal
            isOpen={showModal}
            close={closeModal}
            imgSrc={images[activeModalImageIndex]?.src}
          />
        </div>
      )} 

      <div className="slideshow-wrapper">
        <div
          className={`slideshow-container ${isDragging ? "is-dragging" : ""}`}
          ref={touchContainerRef}
          onMouseDown={mouseHandlers.handleStart}
          onMouseMove={mouseHandlers.handleMove}
          onMouseUp={mouseHandlers.handleEnd}
          onTouchStart={touchHandlers.handleStart}
          onTouchMove={touchHandlers.handleMove}
          onTouchEnd={touchHandlers.handleEnd}
        >
          {imageElements}
          <div className="image-subtitle">
            <p>{images[activeIndex].subtitle}</p>
          </div>
          <button onClick={previousImage} className="nav-button prev">
            ←
          </button>
          <button onClick={nextImage} className="nav-button next">
            →
          </button>
          <div className="dots-container">
            {images.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === activeIndex ? "active" : ""}`}
                onClick={() => handleButtonClick(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Slideshow;
 