import React, { useState } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBCollapse,
  MDBIcon,
  MDBNavbarNav,
} from "mdb-react-ui-kit";
import sherlockLogo from "../../images/sherlock-logo@4x.png";
import { Link, NavLink } from "react-router-dom";
import ImgSh from "../../components/Images/ImgSh";



function NavBarItem({ nav, link, onClick }) {
  // This styling will be applied to a <NavLink> when the
  // route that it links to is currently selected.
  let activeStyle = {
    textDecoration: "underline",
  };

  let activeClassName = "nav-link selected";
  let normalClassName = "nav-link";
  
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <MDBNavbarItem className="p-5">
      <NavLink
        to={link}
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className={({ isActive }) => (isActive ? activeClassName : normalClassName)}
        onClick={handleOnClick}
      >
        {nav}
      </NavLink>
    </MDBNavbarItem>
  );
}

const MaterialNavbar = () => {
  const [showNavNoTogglerSecond, setShowNavNoTogglerSecond] = useState(false);
  
  let text = {
    nav1: "Home",
    link1: "/",
    nav2: "About",
    link2: "/about",
    nav3: "Pipeline",
    link3: "/pipeline",
    nav4: "Services",
    link4: "/services",
    nav5: "Security",
    link5: "/security",
    nav6: "Contact",
    link6: "/contact",
  };

  const handleNavItemClicked = () => {
    setShowNavNoTogglerSecond(false);
  };

  return (
    <>
      <MDBNavbar className="navbar-custom" expand="lg" light bgColor="light">
        <MDBContainer fluid>
          <Link to={text.link1}>
            
            <img src={sherlockLogo} alt="The Sherlock Company Logo" className="navbar-brand-mobile"  />
          </Link>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded={showNavNoTogglerSecond ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={() => setShowNavNoTogglerSecond(!showNavNoTogglerSecond)}
          >
            {showNavNoTogglerSecond ? (
              <MDBIcon icon="times" fas />
            ) : (
              <MDBIcon icon="bars" fas />
            )}
          </MDBNavbarToggler>

          <MDBCollapse
            navbar
            className={`justify-content-center ${showNavNoTogglerSecond ? "show" : ""}`}
          >
            <MDBNavbarNav className="justify-content-center mb-6 mb-lg-0">
              <NavBarItem
                nav={text.nav1}
                link={text.link1}
                onClick={handleNavItemClicked}
              ></NavBarItem>
              <NavBarItem
                nav={text.nav2}
                link={text.link2}
                onClick={handleNavItemClicked}
              ></NavBarItem>
              <NavBarItem
                nav={text.nav3}
                link={text.link3}
                onClick={handleNavItemClicked}
              ></NavBarItem>
              <MDBNavbarBrand href="/">
                
                  
                  <ImgSh src={sherlockLogo} alt="The Sherlock Company Logo" />
                
              </MDBNavbarBrand>
              <NavBarItem
                nav={text.nav4}
                link={text.link4}
                onClick={handleNavItemClicked}
              ></NavBarItem>
              <NavBarItem
                nav={text.nav5}
                link={text.link5}
                onClick={handleNavItemClicked}
              ></NavBarItem>
              <NavBarItem
                nav={text.nav6}
                link={text.link6}
                onClick={handleNavItemClicked}
              ></NavBarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
};

export default MaterialNavbar;
