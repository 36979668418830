import ArticleImg1 from "./images/SH-ARTICLES/article1.webp";
import ArticleImg2 from "./images/SH-ARTICLES/article2.webp";
import ArticleImg3 from "./images/SH-ARTICLES/article3.webp";
import ArticleImg4 from "./images/SH-ARTICLES/article4.webp";
import faceImg from "./images/faces@4x.svg";
import mapImg from "./images/mapImg@4x.svg";
import presentationProducts from "./images/presentation-products@4x.webp";
import pipelineImg1 from "./images/pipeline-img-1@4x.webp";
import pipelineImg2 from "./images/pipeline-img-2@4x.webp";
import pipelineLogo from "./images/sherlock-pipeline-logo@4x.webp";
import createImg from "./images/Create-img@4x.webp";
import adaptImg from "./images/Adapt-img@4x.webp";
import manageImg from "./images/Desktop-img@4x.webp";
import mandoBg from "./images/mando-bg@4x.webp";
import mandoBgNoTT from "./images/mando-bg-noTT@4x.webp";
import mandolanguage1 from "./images/mandolanguage1@4x.webp";
import mandolanguage2 from "./images/mandolanguage2@4x.webp";
import ProjectManagementImage from "./images/amy_svg/illustration_1.svg";
import DataManagementImage from "./images/amy_svg/illustration_2.svg";
import GlobalSupportImage from "./images/amy_svg/illustration_3.svg";
import contactImg from "./images/contact_page.svg";
import mobile_img_1 from "./images/mobileImgs/mobile-contact.svg";

export const content = {
  pages: [
    {
      id: "Homepage",
      manageAnimationTitle: "We manage your",
      faceImg: faceImg,
      copyManage: {
        subTitle:
          "The Sherlock Company localizes and delivers your creative and marketing assets anywhere, in any spec or language, in any timeframe. ",
        btnMain: "Contact Us",
        btnSecondary: "View Services",
      },
      ManageData: [
        {
          id: 1,
          text: "project data.",
        },
        {
          id: 2,
          text: "brand delivery.",
        },
        {
          id: 3,
          text: "translation.",
        },
        {
          id: 4,
          text: "creative projects.",
        },
        {
          id: 5,
          text: "product launches.",
        },
        {
          id: 6,
          text: "ad creation.",
        },
        {
          id: 7,
          text: "creative design.",
        },
      ],
      videoData: {
        src: "https://player.vimeo.com/video/827803255?h=71154855ce&badge=0&autopause=0&player_id=0&app_id=58479",
      },
      logoData: {
        title:
          "We’ve worked with the world's biggest brands to deliver their biggest global launches.",
      },
      projectManagement: {
        image: ProjectManagementImage,
        direction: "textLeft",
        mdNumberImg: 6,
        mdNumberTxt: 6,
        titleColor: "#FFA933",
        title: "Project Management",
        copy: "Brief creation. Proof of concepts. Quote and budget approval. Design and localization management. Fast, simplified delivery. Your dedicated team works with you to build a workflow based on your needs, and can take a lot of tasks off your plate. We even help manage other vendors.",
        mouseEffectOn: true,
        threshold: 1,
        scaleFactor: 1.01,
      },
      dataManagement: {
        image: DataManagementImage,
        direction: "textRight",
        mdNumberImg: 7,
        mdNumberTxt: 5,
        titleColor: "#314476",
        title: "Data Management",
        copy: "From business insights to project tracking to translation management, every project provides a massive amount of data that’s a critical part of getting what you need built quickly. Our focus on managing your data means we’re constantly improving, while providing you with customized, actionable insights on-demand.",
        mouseEffectOn: true,
        threshold: 1,
        scaleFactor: 1.01,
      },
      globalSupport: {
        image: GlobalSupportImage,
        direction: "textLeft",
        mdNumberImg: 7,
        mdNumberTxt: 5,
        titleColor: "#009193",
        title: "Global Support",
        copy: "Your work is global, and so is our team. We’re all over the world and are proud to offer round-the-clock support from our dedicated teams with seamless handover. So you can launch a project from Tokyo and get it delivered from London. No turbulence or delays.",
        mouseEffectOn: true,
        threshold: 1,
        scaleFactor: 1.01,
      },
      mapImg: mapImg,
      mapTitle: "We’re worldwide.",
      mapSubtitle: "And some of us just like working late!",
      mapCopy: "12 major cities • 24/7 global coverage • 150+ creative localization specialists • 300+ languages and dialects • 100,000+ perfectly-localized assets per month",
      mapCities: "Los Angeles, Phoenix, Houston, New York City, Jacksonville, São Paulo, London, Paris, Belgrade, Cairo, Tokyo, Sydney",
      presentationProductsImg: presentationProducts,
      pipeline: {
        pipelineImg1: pipelineImg1,
        pipelineImg2: pipelineImg2,
        pipelineLogo: pipelineLogo,
        createImg: createImg,
        createImg2: mandoBg,
        createImg3: mandolanguage1,
        createImg4: mandolanguage2,
        mandoBgNoTT: mandoBgNoTT,
        adaptImg: adaptImg,
        manageImg: manageImg,
      },
    },
    // homepage end

    {
      id: "Pipeline",
      mainSection: {
        miniTitle: "Powered by",
        copy: "Our software keeps every element of your project together, in one place, while helping us produce and deliver your assets. You can order new projects, manage spend, produce instant adaptations, track your work, and get automated delivery—all from your homepage. It’s your favorite software you’ve never heard of.",
        btnMain: "Request a Demo",
      },
      sectionOne: {
        title: "Automated work, flows",
        copy: "Pipeline automates key parts of your workflow, allowing us to create on-brand, on-spec assets at scale. The rest is handled by your dedicated team of project experts.",
      },
      sectionTwo: {
        title: "Keep your assets safe",
        copy: "Security is our biggest priority. Our top-of-the-line security measures ensure all your most sensitive data and pre-launch assets stay protected.",
      },
      orderSection: {
        title: "Order",
        copy: "Your team can order translations and variations of existing assets via our asset library, or brief in entirely new projects with our order tool.",
      },
      deliverSection: {
        title: "Deliver",
        copy: "We deliver anywhere and to any number of destinations. Our automated delivery processes, including auto file naming and asset sizing, mean your project can be completed in just one click.",
      },
      editSection: {
        title: "Edit and Review",
        copy: "Seamless review tools allow you to mark up, comment, and leave decisions on assets within the Pipeline workflow. You can even make your own adjustments to assets in the tool.",
      },
      storeSection: {
        title: "Store",
        copy: "Upload, organize, search, and order variations with our free-to-use library. Pipeline offers a powerful digital asset management platform that can work with your existing DAM or serve as your team’s core solution.",
      },
      trackSection: {
        title: "Track",
        copy: "Track project progress, spending, delivered assets, and more from your dashboard, where you get powerful insights on demand.",
      },
    },
    // pipeline end

    {
      id: "PrivacyPolicy",
      introduction: {
        title: "Introduction",
        text: [
          {
            content:
              "We at Holmes-Moriarty, LLC dba The Sherlock Company, and our affiliates and subsidiaries (“The Sherlock Company,” “we,” “us,” or “our”) know you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our privacy practices, which will describe how and why we might collect, store, use, and/or share (“process”) your information when you use our services (“Services”) or websites (“Sites”).",
            newParagraph: true,
          },
          {
            newParagraph: true,
            content:
              "This privacy policy tells you about the information we collect from you when you use our services and websites. In collecting this information, we are acting as a data controller and, by law, we are required to provide you with information about us, about why and how we use your data and personal information, and about the rights you have over your data and personal information. By using or accessing our website,",
          },
          {
            continueLine: true,
            content: "www.thesherlockcompany.com",
            link: "www.thesherlockcompany.com",
          },
          {
            continueLine: true,
            content:
              ", Sherlock Pipeline, and other websites that we operate and link to this policy (collectively, the “Sites”) in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.",
          },
        ],
      },
    },

    {
      id: "About",
      text: [
        "Sherlock was born out of a frustration with both traditional agency processes and traditional enterprise software. Both made huge promises, but always came up short.",
        "Sherlock aims to change that.",
        "We’re a nimble, independent organization with a passion for improving our customers’ experiences at every touch point. We’re able to adapt our processes quickly, allowing us to work with our clients to design the best approach to any of their problems.",
        "We get it done with a relentless focus on customer service, empowered teams, and a willingness to try new things.",
      ],
    },

    {
      id: "Services",
      titles: [{
        id: 1,
        text: "localization.",
      },
      { 
        id: 2,
        text: "responsive ads.",
      },
      {
        id: 3,
  
        text: "automatic resizing.",
      },
      {
        id: 4,
        text: "product launches.",
  
      },
      {
        id: 5,
        text: "translation.",
      },
      {
        id: 6,
        text: "subtitling & dubbing.",
      },
      {
        id: 7,
        text: "creative design.",
      }],
      createSection: {
        title: "Create.",
        copy: "Whatever you need, we make it happen. We become experts in your brand and can handle any project, from subtitling and dubbing to resizing keyart to creative executions.",
      },
      localizeSection: {
        title: "Localize.",
        copy: "We offer translation services in over 300 languages and dialects, utilizing a network of 5,000 linguists and state-of-the-art AI and Translation Memory technology.",
      },
      deliverSection: {
        title: "Deliver.",
        copy: "All you have to do is approve the assets and we take care of the rest. Streamlined, on-time delivery, no matter how complex the requirements.",
      },
      manageSection: {
        title: "Manage.",
        copy: "We adapt our workflow to yours, so our teams can take on key areas of management for your projects—like brief creation, budgeting, even coordinating other vendors.",
      },
      analyzeSection: {
        copy: "Say goodbye to those 50-message-deep “Status?” email threads. We provide real-time insights about your projects, giving you actionable information to help improve efficiencies and make sure everything stays on track.",
      },
    },

    {
      id: "Contact",
      images: {
        contactImg: contactImg,
        mobileImg: mobile_img_1,
      },
      headers: {
        mainHeader: "Click here to Contact us",
        subHeader:
          "We’ll come back to you as soon as we can.",
        footerHeader: "Help with existing services",
        footerSub: "Fill out this form if you need help or support on an existing product or service, and we’ll come back to you as soon as we can.",
        footerOther: "Or, leave us a message the old-fashioned way:",
      },
      contactInfo: {
        email: {
          text: "email:",
          link: "mailto:info@sherlockhm.com",
          display: "info@sherlockhm.com",
        },
        phone: "+1 (818) 480-8041",
        address: [
          "Mailing Address (US):",
          "401 Wilshire Blvd, 12th Floor",
          "Santa Monica, CA 90401",
        ],
      },
    },
    {
      id: "Policies",
    },
    {
      // Elementary Page
      id: "Elementary",
      title: "Your dedicated Sherlock team",
      copy: "featuring the latest industry developments, best practices, and actionable advice for businesses looking to thrive in the global marketplace.",
      showcaseTitle: "sherlock’s showcase",
      showcaseItems: [
        {
          // Elementary Showcase Item
          id: "how-sherlock-handles-streaming",
          img: ArticleImg2,
          title: "How Sherlock Handles Simultaneous Global Streaming Launches",
          readingTime: "around a 4 min read",
          articleSections: [
            {
              title:
                "Successfully Navigating the Global Launches of Disney+ and HBO Max",
              content:
                "In Q4 2019 and Q1 2020, Disney+ and HBO Max launched their respective globally-competitive streaming services in dozens of international markets. Behind the scenes, a significant challenge lay in translating, localizing, and delivering an enormous volume of marketing assets within a tight timeframe. Both streaming services relied on Sherlock to execute the localization and delivery of their assets, and we took on the challenge by employing a combination of technology, expertise, and streamlined project management to ensure a seamless launch for both services. The task at hand was a big one – over 1.4 million assets needed to be translated, localized, and delivered to various destinations. Here’s how we successfully delivered all assets with no delayed launch dates:",
            },
            {
              title: "Effective Data Management",
              content:
                "Handling huge projects can be difficult simply because it becomes impossible to track the location and progress of millions of changing parts, files, comments, and versions. (We’ve all been part of those 50-email-long threads, sheepishly attaching file_final_v3_finalfinal.psd.) Instead, we utilized Sherlock Pipeline to keep everything in one place. Pipeline provided us with version control, real-time deliverable status, and project analytics—all in one place.",
            },
            {
              title: "Efficiency through Automation",
              content:
                "One of the keys to our success is using automation where it really matters. Pipeline was instrumental in automatically generating localized versions and derivatives of each asset. This meant that entire suites of marketing materials, social media content, and creative assets were instantaneously adapted to meet the requirements of specific regions and demographics. Those assets then went through Pipeline’s QC platform, allowing for bulk delivery of brand compliant assets while adhering to strict deadlines.",
            },
            {
              title: "Expertise in Brand Guidelines",
              content:
                "Our team of designers and project managers become experts in the brand guidelines of all our clients, and these projects were no different. From layout considerations to title treatment recreations, our team kept client revisions low by keeping brand guidelines and best practices top of mind. Plus, Pipeline integrates important guidelines like safety areas directly into our QC tool.",
            },
            {
              title: "Comprehensive Project Management",
              content:
                "Managing such large-scale projects requires a holistic approach. We took full ownership of these projects, providing end-to-end services from file sourcing to vendor management to delivery. By taking on the project coordination, we allowed our busy clients to focus on other aspects of their global launch strategies. On our end, managing each project from start to finish meant better quality control, streamlined asset creation, and fewer mistakes.",
            },
            {
              title: "Results",
              content:
                "We successfully delivered both studios’ assets on time and without issues. We’ve continued to provide both Disney and Hulu with ongoing support in their continued international expansion. Interested in learning how we might help manage your projects? Drop us a line.",
            },
          ],
        },
        {
          id: "Multi-Vendor-Management",
          img: ArticleImg3,
          title: "Multi-Vendor Asset Management ",
          readingTime: "around a 3 min read",
          articleSections: [
            {
              title:
                "Revamping Asset Management for Disney & Warner Discovery Libraries",
              content:
                "When it comes to dealing with the digital catalogs of studio giants like Disney and Warner, there’s a lot to contend with. From digitized versions of older titles, to uniquely laid-out key art for a new streaming platform, to trailers released for every social media format, to localized versions of all of those assets—it’s no wonder that maintaining a unified, complete library of assets is a challenge. Both companies recognized the need for standardization of key-arts across thousands of titles in multiple regions, spanning over 30 languages, so they asked our team if we’d be willing to take it on. We took a look at the ask and realized we already had a built-in solution.",
            },
            {
              title: "Collaborative Vendor Management",
              content:
                "No one agency could take on the entire catalog—this had to be a team effort. But our clients were, understandably, concerned about quality or design differences in deliverables worked on by disparate teams. Luckily, the unique strength of The Sherlock Company lies in our ability to integrate and coordinate the work of multiple vendors through our project management platform, Pipeline, as well as our detailed, multi-step QC process. In this instance, we were entrusted to oversee the tasks of four different vendors, each with their own unique strengths and specialties. Our role was to ensure that these distinct entities worked harmoniously to achieve a common goal - a standardized library of key-art assets.",
            },
            {
              title: "Centralization with Sherlock Pipeline",
              content:
                "Harnessing the power of our proprietary tool, Sherlock Pipeline, we were able to centralize the process of receiving, revising, and delivering localized assets. Our working file management system ensured that only the most recent versions of each file were being reviewed and edited. And because all assets were being uploaded to our system, they all went through our tried-and-tested QC process, which has resulted in <1% errors on our largest projects. Finally, all assets were delivered via our automated delivery system which conducts a final QC on file size and weight, and standardizes filenames, eliminating the need for manual file naming and ensuring total consistency across all deliverables.",
            },
            {
              title: "Tangible Results",
              content:
                "We were pleased (although maybe not that surprised) to see the project culminate in the successful, on-schedule delivery of more than 500,000 assets. Both Disney and Warner Discovery asked us to replicate this model for asset management in other regions. Interested in learning more about how we work? Contact us today for a personalized demo.",
            },
          ],
        },
        {
          id: "Metadata-Cleanup",
          img: ArticleImg4,
          title: "A Deep Dive into Metadata Cleanup",
          readingTime: "around a 5 min read",
          articleSections: [
            {
              title: "Revitalizing the Miramax Library",
              content:
                "So you’ve acquired a film studio: now what? One of the less glamorous (but, in our opinion, most interesting) aspects of an acquisition lies in understanding, and standardizing, your studio’s metadata. The Miramax library changed hands three times in the home entertainment distribution sector before finally being acquired by a major Hollywood studio. This series of transitions posed a complex challenge: the need for a comprehensive audit, restoration, and standardization of metadata for the portfolio's top-performing titles. The scope of work extended to a suite of assets produced for 26 countries and 30 languages.",
            },
            {
              title: "The Challenge",
              content:
                "Metadata is a key part of keeping a film library running. Information like local titles, ratings, and various IDs play a crucial role in the global distribution and monetization of these titles. Unfortunately, the multiple transitions of the Miramax library had resulted in disorganized and inconsistent metadata, which was a significant barrier to the library's potential to generate revenue on transactional and streaming platforms worldwide.",
            },
            {
              title: "Sherlock's Solution",
              content:
                "To tackle this formidable task, we deployed our Sherlock Metadata Aggregator Tool, which gathers and verifies official metadata from local-market sources according to parameters agreed upon by Sherlock and the client. We were quickly able to identify gaps in the catalog, allowing our team to fill in up-to-date metadata and, where needed, even create localized artwork from scratch. ",
            },
            {
              content:
                "It was a meticulous process, aided by our proprietary technology as well as our expert team of project managers and QC managers, who possess deep knowledge of studio requirements as well as the needs of local markets. "
            },
            {
              title: "Incorporating Local Market Insights",
              content:
                "Localizing artwork and metadata for 26 countries and 30 languages demands a deep understanding of each market, linguistic nuances, and cultural context. Our team of localization experts was instrumental in navigating these complexities, ensuring that each localized asset was accurate, relevant, and compliant with local guidelines.",
            },
            {
              title: "Tangible Results",
              content:
                "Within a span of less than 90 days, we delivered over 10,000 localized data points and territory-specific assets. This massive effort resulted in a normalized, revenue-ready portfolio for the new Hollywood studio owner, allowing the Miramax library to generate revenue on transactional and streaming platforms across the globe.",
            },
            {
              content: 
              "If you're facing similar challenges in data management, metadata standardization, or asset localization, reach out and see if you can leverage our expertise."
            },

          ],
        },
        {
          id: "Sonos-Hi-Tech",
          img: ArticleImg1,
          title: "Global Ads for Sonos Hi-Tech Speakers",
          readingTime: "around a 4 min read",
          articleSections: [
            {
              content:
                "Sonos, a premium smart speaker manufacturer, sought to create and localize high-quality ads for global reach in a short turnaround time. Sherlock was tasked with designing, producing, and localizing static and motion-graphic advertising materials for use across various channels over 4 continents.",
            },
            {
              content:
                "The mission was a colossal one – thousands of assets needed to be created and localized annually. Here’s how we successfully executed this:",
            },
            {
              title: "Unifying Design and Production Management",
              content:
                "Managing large projects can be daunting due to the sheer volume of moving parts, files, and versions to track. We employed Sherlock Pipeline to keep everything organized. Pipeline offers built-in version control, real-time status tracking, and project analytics, ensuring that all materials remain in one accessible and efficient platform.",
            },
            {
              title: "Efficacy through Automation",
              content:
                "Here at Sherlock, we recognize the importance of an expert team coupled with strategic automation. Pipeline proved crucial in the automatic generation of localized versions and derivatives of each asset. This ensured that whole suites of marketing materials, social media content, and creative assets were promptly adapted to the requirements of specific regions and demographics. After creation, these assets passed through Pipeline’s QC platform, facilitating bulk delivery of brand-compliant assets within tight deadlines.",
            },
            {
              title: "Expertise in Brand Guidelines",
              content:
                "Our team of designers and project managers become experts in the brand guidelines of our clients, and this project was no different. From layout considerations to recreating ad treatments, our team ensured client revisions were minimal by prioritizing the brand guidelines and best practices. Pipeline integrates essential guidelines, such as safety areas, directly into our QC tool, making the review process a breeze.",
            },
            {
              title: "Holistic Project Management",
              content:
                "We took full ownership of this project, providing end-to-end services from concept development to localization and final delivery. Overseeing each project from start to finish ensured better quality control, streamlined asset creation, and reduced errors.",
            },
            {
              title: "Results",
              content:
                "We successfully created and localized thousands of assets for Sonos' global product launches annually. Sherlock continues to provide ongoing support to Sonos in their continued global marketing endeavors.",
            },
            {
              content:
                "Interested in learning how we might help manage your projects? Feel free to get in touch.",
            },
          ],
        },
      ],
    }, // end of Elementary,
  ],
};
