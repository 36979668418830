import React from "react";
import { Link } from "react-router-dom";

function Bugbounty() {
  const exclusions = [
    "Distributed Denial of Service (DDoS)",
    "Spamming",
    "Social engineering or phishing of Holmes Moriarty, LLC dba The Sherlock Company employees or contractors",
    "Any attacks against Holmes Moriarty, LLC dba The Sherlock Company’s physical property or data centers",
  ];

  return (
    <div className="container privacyPolicy">
      <h1 className="text-center my-5">Responsible Disclosure Policy</h1>

      <div className="my-4">
        <p>
          Data security is a top priority for Holmes Moriarty, LLC dba The
          Sherlock Company, and Holmes Moriarty, LLC dba The Sherlock Company
          believes that working with skilled security researchers can identify
          weaknesses in any technology.
        </p>
        <p>
          If you believe you've found a security vulnerability in Holmes
          Moriarty, LLC dba The Sherlock Company’s service, please notify us; we
          will work with you to resolve the issue promptly.
        </p>
      </div>

      <div className="my-4">
        <h3 className="mb-3">Disclosure Policy</h3>
        <ul className="list">
          <li className="bullet-item">
            Please report all security incidents, potential vulnerabilities, or
            other security inquiries to <a href="mailto:security@sherlockhm.com">security@sherlockhm.com</a>. We will
            acknowledge your email promptly.
          </li>
          <li className="bullet-item">
            Provide us with a reasonable amount of time to resolve the issue
            before disclosing it to the public or a third party.
          </li>
          <li className="bullet-item">
            Make a good faith effort to avoid violating privacy, destroying
            data, or interrupting or degrading the Holmes Moriarty, LLC dba The
            Sherlock Company service. Please only interact with accounts you own
            or for which you have explicit permission from the account holder.
          </li>
        </ul>
      </div>

      <div className="my-4">
        <h3 className="mb-3">Exclusions</h3>
        <p>While researching, we’d like you to refrain from:</p>
        <ul className="list">
          {exclusions.map((exclusion, index) => (
            <li key={index} className="bullet-item">
              {exclusion}
            </li>
          ))}
        </ul>
        <p>
          Thank you for helping to keep Holmes Moriarty, LLC dba The Sherlock
          Company and our users safe!
        </p>
      </div>

      <div className="my-4">
        <h3 className="mb-3">Changes</h3>
        <p>
          We may revise these guidelines from time to time. The most current
          version of the guidelines will be available at
          <Link to="/security"> www.sherlockhm.com/security</Link>
        </p>
        <p>
          Holmes Moriarty, LLC dba The Sherlock Company is always open to
          feedback, questions, and suggestions. If you would like to talk to us,
          please feel free to email us at <a href="mailto:security@sherlockhm.com">security@sherlockhm.com</a>
        </p>
      </div>

      <div className="my-4">
        <h3 className="mb-3">Disciplinary Action</h3>
        <p>
          Employees who violate this policy may face disciplinary consequences
          in proportion to their violation. Holmes Moriarty, LLC dba The
          Sherlock Company management will determine how serious an employee’s
          offense is and take the appropriate action.
        </p>
      </div>

      <div className="my-4">
        <h3 className="mb-3">Responsibility</h3>
        <p>
          It is the Holmes Moriarty, LLC dba The Sherlock Company InfoSec team's
          responsibility to see if this policy is enforced.
        </p>
      </div>
    </div>
  );
}

export default Bugbounty;
