import { useEffect, useRef, useCallback } from "react";

export const useTimer = (callback, delay) => {
  const timerRef = useRef(null);

  const stopTimer = useCallback(() => {
    clearInterval(timerRef.current);
  }, []);

  const startTimer = useCallback(() => {
    timerRef.current = setInterval(callback, delay);
  }, [callback, delay]);

  useEffect(() => {
    startTimer();
    return stopTimer;
  }, [startTimer, stopTimer]);

  return { startTimer, stopTimer };
};
