import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import ImageWithText from "../materialComponents/twoColumns/ImageWithText";
import { motion, useScroll, useTransform } from "framer-motion";
import MovingShapes from "../materialComponents/pageFronts/MovingShapes";
import pipelineContentImg from "../images/Pipeline-Content_2.svg";

import MobileImg1 from "../images/mobileImgs/WorkFlows.svg";
import MobileImg2 from "../images/mobileImgs/security.svg";
import Slideshow from "../components/Slideshow";

import pipelineSlideshow1 from "../images/slideshow1.webp";
import pipelineSlideshow2 from "../images/slideshow2.webp";
import pipelineSlideshow3 from "../images/slideshow3.webp";
import pipelineSlideshow4 from "../images/slideshow4.webp";

import pipeline1 from "../images/pipeline_page_1.png";
import pipeline2 from "../images/track.png";
import pipeline3 from "../images/create_and_edit.gif";
import pipeline4 from "../images/pipeline_page_4.svg";
import pipeline5 from "../images/pipeline_page_5.webp";
import { PipelineFullscreenImg } from "./PipelineFullscreenImg";
import ImgSh from "../components/Images/ImgSh";

const Pipelinepage = () => {
  const { scrollYProgress } = useScroll();
  const parallaxY = useTransform(scrollYProgress, [0, 0.1], [-20, 50]);
  const scale = useTransform(scrollYProgress, [0, 0.2], [1.2, 0.9]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const isSmallScreen = windowWidth < 768;

  const pipelineMainFrontContent = {
    id: 1,
    title: "Pipeline page title",
    copy: "Pipeline page subtitle",
  };

  const images = [
    { src: pipelineSlideshow2, subtitle: 'Use your dashboard to get an overview of where your projects are, in real time.' },
    { src: pipelineSlideshow4, subtitle: 'Need to swap out a logo or artwork? Save time by reviewing and editing assets in bulk.' },
    { src: pipelineSlideshow1, subtitle: 'Easily compare approved and previous versions of your project assets.' },
    { src: pipelineSlideshow3, subtitle: 'View, review, and make changes to your project assets with our proprietary design editing tool.' },
  ];

  const pipelineContentInfo = [
    {
      id: 2,
      titleColor: "#FFA933",
      image: pipeline1,
      direction: "textLeft",
      title: "Order",
      copy: "Your team can order translations and variations of existing assets via our asset library, or brief in entirely new projects with our order tool.",
      mdNumberTxt: "4",
      mdNumberImg: "8",
    },
    {
      id: 3,
      titleColor: "#314476",
      image: pipeline4,
      direction: "textRight",
      title: "Deliver",
      copy: "We deliver anywhere and to any number of destinations. Our automated delivery processes, including auto file naming and asset sizing, mean your project can be completed in just one click.",
      mdNumberTxt: "4",
      mdNumberImg: "8",
      mouseEffectOn: true,
    },
    {
      id: 4,
      titleColor: "#009193",
      image: pipeline3,
      direction: "textLeft",
      title: "Edit and Review",
      copy: "Seamless review tools allow you to mark up, comment, and leave decisions on assets within the Pipeline workflow. You can even make your own adjustments to assets in the tool.",
      mdNumberTxt: "4",
      mdNumberImg: "8",
      html: "edit",
      mouseEffectOn: true,
    },
    {
      id: 5,
      titleColor: "#314476",
      image: pipeline5,
      direction: "textRight",
      title: "Store",
      copy: "Upload, organize, search, and order variations with our free-to-use library. Pipeline offers a powerful digital asset management platform that can work with your existing DAM or serve as your team’s core solution.",
      mdNumberTxt: "4",
      mdNumberImg: "8",
      mouseEffectOn: true,
    },
    {
      id: 6,
      titleColor: "#F27059",
      image: pipeline2,
      direction: "textLeft",
      title: "Track",
      copy: "Track project progress, spending, delivered assets, and more from your dashboard, where you get powerful insights on demand.",
      mdNumberTxt: "4",
      mdNumberImg: "8",
    },
  ];

  return (
    <div>
    
      <MovingShapes />
      
      <PipelineFullscreenImg
        parallaxY={parallaxY}
        scale={scale}
        isSmallScreen={isSmallScreen}
        yAmountSway={20}
        xAmountSway={10}
        scaleAmount={0.03}
        rotateXAmount={2}
        rotateYAmount={1}
        motionDuration={17}
      />
      
      <div
        className="svg-container container"
        style={{
          minHeight: "60vh",
          zIndex: "2",
          top: isSmallScreen ? 0 : -100,
        }}
      >
        <div className="txt1 floating-text-mobile">
          <ImgSh className="d-block mobile-show-only" src={MobileImg1} />

          <h2 className="txt-blue">Automated work, flows</h2>
          <p>
            Pipeline automates key parts of your workflow, allowing us to create
            on-brand, on-spec assets at scale. The rest is handled by your
            dedicated team of project experts.
          </p>
        </div>

        <div className="txt2 floating-text-mobile" style={{ zIndex: "2" }}>
          <ImgSh className="d-block mobile-show-only" src={MobileImg2} />
          <h2 className="txt-green">Keep your assets safe</h2>
          <p>
            Security is our biggest priority. Our top-of-the-line security
            measures ensure all your most sensitive data and pre-launch assets
            stay protected.
          </p>
        </div>
        <motion.div style={{ zIndex: "11", position: "relative" }}>
          <ImgSh
            className="pipeline-content-svg mobile-no-show"
            src={pipelineContentImg}
            width="1515"
            height="1348"
          />
        </motion.div>
      </div>

      <Slideshow images={images} />

      <MDBContainer>
        {pipelineContentInfo.map((content) => (
          <ImageWithText
            key={content.id}
            image={content.image}
            direction={content.direction}
            title={content.title}
            titleColor={content.titleColor}
            copy={content.copy}
            mdNumberTxt={content.mdNumberTxt}
            mdNumberImg={content.mdNumberImg}
            mouseEffectOn={content.mouseEffectOn}
          />
        ))}
      </MDBContainer>
    </div>
  );
};

export default Pipelinepage;
