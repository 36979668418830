import React from "react";
import { useParams, Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdbreact';

import { motion } from "framer-motion";
import TextItemH1 from "../../materialComponents/TextItems/TextItemH1";
import Button from "../../components/Button";

const getRandomShowcaseItems = (items) => {
    let shuffled = [...items];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled.slice(0, 3);
};

const ShowcaseItemPage = ({ content }) => {
    let { id } = useParams();
  
    // find the "Elementary" page
    const elementaryPage = content.pages.find(page => page.id === "Elementary");
    
    if (!elementaryPage) {
      return <div>"Elementary" page not found.</div>;
    }
  
    // find the showcase item in the "Elementary" page
    const showcaseItem = elementaryPage.showcaseItems.find(item => item.id === id);
  
    if (!showcaseItem) {
      return <div>Showcase item not found.</div>;
    }

    // find the showcase items in the "Elementary" page
    const showcaseItems = elementaryPage.showcaseItems;
    const randomShowcaseItems = getRandomShowcaseItems(showcaseItems);
  
    const articleSections = showcaseItem.articleSections;
  
    return (
        <MDBContainer>
            <motion.div
    //   initial={{ opacity: 0 }}
    //   animate={{  opacity: 1 }}
    //   exit={{ opacity: 0, ease: "easeInOut" }}
    //   transition={{
    //     duration: 0.5,
    //     ease: "easeInOut",
    //   }}
            >
                <MDBRow>
                    <MDBCol md="12" className="text-center">
                        <Button
                            Type="secondary"
                            Text="← Back to Elementary"
                            btnLink="/elementary"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="md-5 justify-content-center">
                    <MDBCol className="text-center" md="10">
                        {/* img with col-12  */}
                        {showcaseItem.img && (
                            <img
                                class="p-3"
                                src={showcaseItem.img}
                                alt={showcaseItem.title}
                                className="img-fluid showcase-item-img"
                            />
                        )}
                        <TextItemH1 title={showcaseItem.title} />
                        <p>{showcaseItem.readingTime}</p>
                    </MDBCol>
                </MDBRow>
                {articleSections.map((section, index) => (
                    <MDBRow className="mt-5 pd-2 justify-content-center" key={index}>
                        <MDBCol xl="6" lg="9" md="10" sm="12" xs="12">
                            {/* if section.title exists, display the h4 */}
                            {section.title && <h3 style={{padding:"1rem 0" }}>{section.title}</h3>}
                            <p style={{fontSize:20, color:"#777", lineHeight:"130%"}} >{section.content}</p>
                        </MDBCol>
                    </MDBRow>
                ))}
                <MDBRow className="m-5 justify-content-center">
                    <MDBCol className="text-center" md="6">
                        <Button Text="Contact Us" btnLink="/contact" />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    {randomShowcaseItems.map((item, index) => (
                        <MDBCol md="4" key={index}>
                            <Link to={`/elementary/${item.id}`}>
                              <MDBCard>
                              <MDBCardBody>

                                {item.title && 
                                <MDBCardTitle>{item.title}</MDBCardTitle>}
                                {item.img && (
                                    <img
                                        class="p-3"
                                        src={item.img}
                                        alt={item.title}
                                        className="img-fluid"
                                    />
                                )}
                                
                                <MDBCardText>{item.readingTime}</MDBCardText>
                                
                                </MDBCardBody>
                                </MDBCard>
                            </Link>
                        </MDBCol>
                    ))}
                </MDBRow>
            </motion.div>
        </MDBContainer>
    );
};

export default ShowcaseItemPage;
