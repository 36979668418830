import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = ({content}) => {
  const privacyPage = content.pages.find((page) => page.id === "PrivacyPolicy");

  if (!privacyPage) {
    return <div>"About" page not found.</div>;
  }

  const { text } = privacyPage;
  return (
    <div className="container privacyPolicy">
      <h1 className="text-center my-5">Privacy Policy</h1>
      <p>Last updated June 28, 2024</p>

      <div className="my-3">
        <h3 className="mb-2">Introduction</h3>

        <p>
          We at Holmes-Moriarty, LLC dba The Sherlock Company, and our
          affiliates and subsidiaries (“The Sherlock Company,” “we,” “us,” or
          “our”) know you care about how your personal information is used and
          shared, and we take your privacy seriously. Please read the following
          to learn more about our privacy practices, which will describe how and
          why we might collect, store, use, and/or share (“process”) your
          information when you use our services (“Services”) or websites
          (“Sites”).
        </p>
        <p>
          This privacy policy tells you about the information we collect from
          you when you use our services and websites. In collecting this
          information, we are acting as a data controller and, by law, we are
          required to provide you with information about us, about why and how
          we use your data and personal information, and about the rights you
          have over your data and personal information. By using or accessing
          our website
          <Link to="/"> www.thesherlockcompany.com</Link>, Sherlock Pipeline, and
          other websites that we operate and link to this policy (collectively,
          the “Sites”) in any manner, you acknowledge that you accept the
          practices and policies outlined in this Privacy Policy, and you hereby
          consent that we will collect, use, and share your information in the
          following ways.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">Questions or concerns?</h3>
        <p>
          Reading this policy will help you understand your privacy rights and
          choices. If you have any questions or concerns, please contact us at
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">Who are we?</h3>
        <p>
          We are Holmes Moriarty, LLC dba The Sherlock Company. Our address is
          401 Wilshire Blvd, 12th Floor, Santa Monica, CA 90401. You can contact
          us by sending an email to
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">What information do we collect?</h3>
        <p>
          We collect personal information that you voluntarily provide to us
          when you express an interest in receiving information about us, our
          products, Services and/or Sites; when you participate in any related
          activities or when you contact us in any way.
        </p>
        <p>
          The personal information that we collect depends on your interactions
          with us and the Services and/or Sites you use; the choices you make;
          and/or features of the Services and Sites you use. The
          personal information we collect may include the following:
        </p>
        <ul className="list">
          <li className="bullet-item">Your name(s)</li>
          <li className="bullet-item">Your phone number(s)</li>
          <li className="bullet-item">Your email address(es)</li>
          <li className="bullet-item">Your company</li>
        </ul>
        <p>
          We do not process sensitive information. The processing of personal
          data such as that listed above shall always be in line with the
          General Data Protection Regulation (GDPR), California Consumer Privacy
          Act (CCPA), as well as regulations in other applicable countries.
        </p>
        <p>
          All personal information that you provide to us must be complete,
          accurate and true; and you must notify us of any changes to such
          personal information.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">
          What kind of information do we automatically collect?
        </h3>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services and/or Sites which can include device and usage
          information such as your IP address, browser and device
          characteristics, operating system, language preferences, referring
          URLs, device name, country, location, information about how and when
          you use our Services and other technical information. This information
          is noted by a unique ID and does not reveal your specific identity
          (such as your name or email address).
        </p>
        <p>
          The information automatically collected is primarily needed to
          maintain security and operation of our Services and for our internal
          analytics and reporting purposes.
        </p>
        <p>Examples of information we collect include:</p>
        <ul className="list">
          <li className="bullet-item">
            <strong>Log and Usage Data.</strong> Log and usage data are
            service-related, diagnostic usage and performance information our
            servers automatically collect when you use or access our Services or
            Sites and which we record in log files. This log data may include
            your IP address, device information, browser type, settings &
            information about your activity in the Services or Sites (such as
            time/date stamps associated with your usage, pages and files viewed,
            searches and other actions you take such as features you use),
            device event information (such as error reports AKA crash dumps,
            hardware settings, etc).
          </li>
          <li className="bullet-item">
            <strong>Device Data.</strong> Information about your computer,
            phone, tablet or other devices you use to access the Services or
            Sites are examples of device data we collect. Such information may
            include your IP address, proxy server, device and/or application
            identification numbers, location, browser type, hardware model,
            Internet service provider, mobile carrier, operating system and/or
            system configuration–depending on how the device is used.
          </li>
          <li className="bullet-item">
            <strong>Location Data.</strong> We collect information data such as
            information about your device’s location. How much information we
            collect depends on the type and settings of the device you use to
            access Services or Sites. You can opt out of allowing us to collect
            this information either by refusing access to the information or by
            disabling your Location setting on your device. However, if you
            choose to opt out, you may not be able to use certain features of
            the Services or Sites.
          </li>
        </ul>
      </div>

      <div className="my-3">
        <h3 className="mb-2">What cookies do we use?</h3>
        <p>
          Some of our Sites use cookies, which are text files that are stored in
          a computer via an internet browser containing a unique ID (string of
          letters and numbers) used to identify you as you use our Sites. This
          unique ID does not make your personal information visible to us.
        </p>
        <p>
          There are various types of cookies, and the cookies we use are called
          session cookies. Session cookies, which are stored for temporary use
          during a limited timeframe, are enabled by default. Their primary
          purpose is to help web pages load faster and improve navigation as you
          visit our Sites. The file is deleted once your session ends.
        </p>
        <p>
          Session cookies fall under the GDPR category of strictly necessary and
          are exempt from GDPR outlined consent requirements.
        </p>
        <p>
          It is possible to accept or reject cookies by adjusting your browser
          settings. If you do not accept our cookies or later disable cookies,
          you may experience reduced functionality in your use of our Sites.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">How do we use your information?</h3>
        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services and Sites including,
          but not limited to:
        </p>
        <ul className="list">
          <li className="bullet-item">
            <strong>
              To respond to user inquiries/offer support to users.
            </strong>
            &nbsp;We may process your information to respond to your inquiries and
            solve any potential issues you might have with the Services or
            Sites.
          </li>
          <li className="bullet-item">
            <strong>
              To send you marketing and promotional communication.
            </strong>
            &nbsp;We may process your personal information for our marketing purposes
            if this follows with your marketing preferences. You can opt out of
            our marketing emails at any time by contacting us via
            <Link to="/contact"> www.thesherlockcompany.com/contact</Link> or
            <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>.
          </li>
          <li className="bullet-item">
            <strong>
              To determine the effectiveness of our marketing and promotional
              campaigns.
            </strong>
            &nbsp;We may process your personal information to better understand how to
            provide campaigns that are the most relevant and effective for you.
          </li>
          <li className="bullet-item">
            <strong>To save or protect an individual’s vital interest.</strong>
            &nbsp;We may process your personal information when necessary to save or
            protect an individual’s vital interest.
          </li>
        </ul>
      </div>

      <div className="my-3">
        <h3 className="mb-2">
          What legal bases do we rely on to process your information?
        </h3>
        <p>
          We only process your personal information when we believe it is
          necessary and we have a valid legal reason to do so under applicable
          law (with your consent), to comply with laws, to protect your rights,
          to provide you access to and usage of Services or Sites, to fulfil our
          contractual obligations or to fulfil our legitimate business
          interests.
        </p>
      </div>

      <div className="my-3">
        <p>
          <i>
            <u>
              If you are located in the EU or UK, this section applies to you
            </u>
          </i>
        </p>
        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on to process your personal
          information. As such, we may rely on the following legal bases to
          process your personal information:
        </p>
        <ul className="list">
          <li className="bullet-item">
            <strong>Consent.</strong> We may process your information if you
            have given us permission (consent) to use your personal information
            for a specific purpose. You can withdraw your consent at any time
            via <Link to="/contact"> www.thesherlockcompany.com/contact</Link> or{" "}
            <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>.
          </li>
          <li className="bullet-item">
            <strong>Performance of a Contract.</strong> We may process your
            personal information when we believe it is necessary to fulfill our
            contractual obligations to you.
          </li>
          <li className="bullet-item">
            <strong>Legitimate Interests.</strong> We may process your personal
            information when we believe it is necessary to achieve our
            legitimate business interests and those interests do not outweigh
            your interests and fundamental rights and freedoms. Examples include
            supporting our marketing activities and sending users information
            about special features about our Services or Sites.
          </li>
          <li className="bullet-item">
            <strong>Vital Interests.</strong> We may process your personal
            information where we believe it is necessary to protect your vital
            interests or the vital interests of a third party, such as
            situations involving potential threats to the safety of any person.
          </li>
        </ul>
      </div>

      <div className="my-3">
        <p>
          <i>
            <u>If you are located in Canada, this section applies to you</u>
          </i>
        </p>
        <p>
          We may process your personal information if you have given us specific
          permission to use it for a specific purpose, or in situations where
          your permission is inferred (implied consent). You can withdrawal your
          consent any time via{" "}
          <Link to="/contact"> www.thesherlockcompany.com/contact</Link> or{" "}
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>.
        </p>
        <p>
          In some cases, we may be legally permitted under applicable law to
          process your personal information without your consent. Examples
          include:
        </p>
        <ul className="list">
          <li className="bullet-item">
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
          </li>
          <li className="bullet-item">
            For investigations and fraud detection and prevention
          </li>
          <li className="bullet-item">
            For business transactions provided certain conditions are met
          </li>
          <li className="bullet-item">
            If it is contained in a witness statement and the collection is
            necessary to assess, process or settle an insurance claim
          </li>
          <li className="bullet-item">
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province
          </li>
          <li className="bullet-item">
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records
          </li>
          <li className="bullet-item">
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </li>
          <li className="bullet-item">
            If the collection is solely for journalistic, artistic or literary
            purposes
          </li>
          <li className="bullet-item">
            If the information if publicly available and is specified by the
            regulations
          </li>
        </ul>
      </div>

      <div className="my-3">
        <h3 className="mb-2">
          Who do we share your personal information with and when?
        </h3>
        <p>
          We may share or transfer your personal information in connection with,
          or during negotiations of, any merger, sale of company assets,
          financing, or acquisition of all or a portion of our business to
          another company.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">How long do we keep your information?</h3>
        <p>
          We keep your personal information only for as long as it is necessary
          for the purposes set out in this Privacy Policy, unless a longer
          retention period is required or permitted by applicable local law
          (such as for tax, accounting or other legal requirements).
        </p>
        <p>
          When we no longer have ongoing legitimate business needs to process
          your personal information, we will either anonymize or delete it, or
          if this is not possible, then we will securely store your personal
          information and isolate it from any further processing until deletion
          is possible.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">How do we keep your information safe?</h3>
        <p>
          We take the security of your personal information very seriously and
          have implemented appropriate and reasonable technical and
          organizational security measures to protect any of your personal
          information we process. However, despite our safeguards and efforts to
          secure your information, no electronic transmissions over the internet
          or information storage measures or technology can be guaranteed
          secured at 100%. So, we cannot promise or guarantee that
          cybercriminals, hackers or other unauthorized parties will not be able
          to defeat our security and improperly collect, access, steal or modify
          your information. Although we do our best to protect your personal
          information, transmission to and from our Services and Sites of your
          personal information is at your own risk. You should only access the
          Services and Sites within a secure environment.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">Do we collect information from minors?</h3>
        <p>
          We do not knowingly solicit or collect any information or market to
          children under the age of 18. By using the Service and Sites, you
          represent that you are at least 18 years old. If we learn that
          personal information from users less than 18 years old has been
          collected, we will deactivate the account and take reasonable measures
          to promptly delete such information from our records. If you become
          aware of any personal information we may have collected from children
          under 18, please contact us at{" "}
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">What are your privacy rights?</h3>
        <p>
          By law, you have privacy rights and can ask us what information we
          have about you and to correct any of it if it is accurate.
        </p>
        <p>
          If we are processing your personal information for reasons of consent
          or to fulfill a contract, you can request a copy of the information in
          a format that you can transfer to another provider. If we are
          processing your personal information for reasons of consent or
          legitimate business interests, you can request that your information
          be erased.
        </p>
        <p>
          You may ask us to stop using your personal information for a period of
          time if you believe we are not doing so lawfully. And, in some
          circumstances, you can ask us not to reach decisions affecting you
          using automated processing.
        </p>
        <p>
          In some regions (such as EEA, UK and Canada), in addition to the
          rights above, you have other certain rights under applicable data
          protection laws including objecting to processing of any of your
          personal information. You may make requests for any of the above or
          submit questions or concerns by contacting us via{" "}
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>,
          <Link to="/contact"> www.thesherlockcompany.com/contact</Link> or by
          mail at:
        </p>

        <p>
          <br />
          Holmes Moriarty, LLC
          <br />
          Attn: Kyle Jones
          <br />
          401 Wilshire Blvd
          <br />
          12th Floor
          <br />
          Santa Monica, CA 90401
          <br />
          United States
          <br />
          <br />
        </p>
        <p>
          For all requests, we will consider and act upon them in accordance
          with applicable data protection laws.
        </p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your Member State data protection authority or UK data
          protection authority, respectively. If you are located in Switzerland,
          you may contact the Federal Data Protection and Information
          Commissioner.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">
          Opting out of marketing and promotional communications:
        </h3>
        <p>
          You can unsubscribe from our marketing and promotional communications
          at any time by clicking on the unsubscribe link in the emails we send,
          by contacting us via
          <Link to="/contact"> www.thesherlockcompany.com/contact</Link> or
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>.
        </p>
        <p>
          Upon request, you will be removed from the marketing lists in within a
          reasonable amount of time. And, we may still communicate with you for
          topics such as service-related messages or for non-marketing purposes.
          If you have any questions or comments, you can contact us via
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">Withdrawing your consent:</h3>
        <p>
          You have the right to withdrawal your consent at any time if we are
          relying on your consent (expressed or implied depending on local law)
          to process your personal information. You can withdrawal via
          <Link to="/contact"> www.thesherlockcompany.com/contact</Link> or{" "}
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>.
        </p>
        <p>
          However, please know that this will not affect the lawfulness of the
          processing before your consent withdrawal nor, when per applicable
          law, will affect the processing of your personal information conducted
          based on lawful processing grounds other than consent.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">Controls for do-not-track features</h3>
        <p>
          Some mobile operating systems and mobile applications and most web
          browsers include a Do-Not-Track (“DNT”) feature or setting that you
          can activate to signal your privacy preference not to have data about
          your online browsing activities monitored and collected.
        </p>
        <p>
          At this stage, no uniform technology standard for recognizing and
          implementing DNT signals has been finalized. As such, we do not
          currently respond to DNT browser signals or any other mechanism that
          automatically communicates your choice not to be tracked online. If a
          standard for online tracking is adopted that we must follow in the
          future, we will revise this policy and notify you of the update.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">
          For California residents
        </h3>
        <p>
          California Civil Code Section 1798.83 permits our users who are
          California residents once a year and free of charge to request and
          obtain information about categories of personal information (if any)
          we disclose to third parties for direct marketing purposes and the
          contact information of all third parties with which we shared personal
          information in the immediately preceding calendar year. If you are a
          California resident and would like to make such a request, please
          contact us via <Link to="/contact"> www.thesherlockcompany.com/contact</Link> or
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">CCPA privacy notice</h3>
        <p>
          The California Code of Regulations defines a “resident” as (1) every
          individual who is in the State of California for other than a
          temporary or transitory period AND (2) every individual who is
          domiciled in the State of California who is outside the State of
          California for a temporary or transitory purpose.
        </p>
        <p>All other individuals are defined as “non-residents.”</p>
        <p>
          If this definition of “resident” applies to you, we must adhere to
          certain rights and obligations about your personal information.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">
          What categories of personal information do we collect?
        </h3>
        <p>
          In the last 12 months, we have collected the following categories of
          personal information:
        </p>
      </div>

      <div className="my-3">
        <table className="table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Examples</th>
              <th>Collected</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Category I: Identifiers</td>
              <td>
                Contact details, such as real name, alias, postal address,
                telephone or mobile contact number, unique personal identifier,
                online identifier, Internet Protocol address, email address, and
                account name
              </td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>
                Category II: Personal information categories listed in the
                California Customer Records statue
              </td>
              <td>
                Name, contact information, education, employment, employment
                history, and financial information
              </td>
              <td>Yes–name and contact information only</td>
            </tr>
            <tr>
              <td>
                Category III: Protected classNameification characteristics under
                California or federal law
              </td>
              <td>Gender and date of birth</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Category IV: Commercial information</td>
              <td>
                Transaction information, purchase history, financial details,
                and payment information
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>Category V: Biometric information</td>
              <td>Fingerprints and voiceprints</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Category VI: Internet or other similar network activity</td>
              <td>
                Browsing history, search history, online behavior, interest
                data, and interactions with our and other websites,
                applications, systems and advertisements
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>Category VII: Geolocation data</td>
              <td>Device location</td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                Category VIII: Audio, electronic, visual, thermal, olfactory or
                similar information
              </td>
              <td>
                Images and audio, video or call recordings created in connection
                with our business activities
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                Category IX: Professional or employment-related information
              </td>
              <td>
                Business contact details in order to provide you our Services
                and Sites at a business level or job title, work history, and
                professional qualifications if you apply for a job with us
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>Category X: Education information</td>
              <td>Student records and directory information</td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                Category XI: Inferences drawn from other personal information
              </td>
              <td>
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about things like an
                individual’s preferences and characteristics
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>Category XII: Sensitive Personal Information</td>
              <td></td>
              <td>No</td>
            </tr>
          </tbody>
        </table>

        <div className="my-3">
          <h3 className="mb-2">For categories I and II</h3>
          <p>
            We will use and/or retain the collected personal information as
            needed to provide these services for as long as the user has an
            account with us.
          </p>
          <p>
            We may also collect other personal information outside of these
            categories through instances when you interact with us in person, by
            phone or mail or online in the context of:
          </p>
          <ul className="list">
            <li className="bullet-item">
              Receiving help through our client support avenues
            </li>
            <li className="bullet-item">
              Participation in client surveys or feedback
            </li>
            <li className="bullet-item">
              Facilitation in the delivery of our Services
            </li>
            <li className="bullet-item">
              Facilitation in responding to your inquiries
            </li>
          </ul>
        </div>

        <div className="my-3">
          <h3 className="mb-2">
            How do we use and share your personal information?
          </h3>
          <p>
            You may contact us by email at{" "}
            <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a> or 
            <Link to="/contact">&nbsp;www.thesherlockcompany.com/contact</Link>
            &nbsp;or refer to the contact details in this policy.
          </p>
          <p>
            If you are using an authorized agent to exercise your right to opt
            out, we may deny a request if the authorized agent does not submit
            proof that they have been validly authorized to act on your behalf.
          </p>
        </div>
      </div>

      <div className="my-3">
        <h3 className="mb-2">Will your information be shared with anyone else?</h3>
        <p>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that possesses the
          information on our behalf, following the same strict privacy
          protection obligations mandated by the CCPA.
        </p>
        <p>
          We may use your personal information for our own business purposes
          such as internal research, development, or demonstrations. This is not
          considered to be "selling" of your personal information.
        </p>
        <p>
          Holmes Moriarty, LLC has not disclosed, sold, or shared any personal
          information to third parties for a business or commercial purpose in
          the preceding twelve (12) months. Holmes Moriarty LLC will not sell or
          share personal information in the future belonging to any of our
          websites' visitors, users, or other consumers.
        </p>
      </div>

      <div className="my-3">
        <p className="mb-2">Right to request deletion of the data</p>
        <p>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law such as our compliance requirements resulting from a
          legal obligation or any processing that may be required to protect
          against illegal activities.
        </p>
      </div>

      <div className="my-3">
        <p className="mb-2">Right to be informed</p>
        <p >Depending on the circumstances, you have a right to know:</p>
        <ul className="list">
          <li className="bullet-item">
            Whether we collected and use your personal information
          </li>
          <li className="bullet-item">
            The categories of personal information we collect
          </li>
          <li className="bullet-item">
            The purposes for which collected personal information is used
          </li>
          <li className="bullet-item">
            Whether we sell or share personal information with third parties
          </li>
          <li className="bullet-item">
            The specific pieces of personal information we collected about you
          </li>
        </ul>
        <p>
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </p>
      </div>

      <div className="my-3">
        <p className="mb-2">Right to non-discrimination</p>
        <p>
          We will not discriminate against you if you exercise your privacy
          rights.
        </p>
      </div>

      <div className="my-3">
        <p className="mb-2">Right to limit use and disclosure</p>
        <p>We do not collect consumer sensitive personal information.</p>
      </div>

      <div className="my-3">
        <p className="mb-2">Verification process</p>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom the request is made. These
          verification efforts require us to ask you to provide information so
          that we can match it to the information you previously provided. And,
          we may use other verification methods as the circumstances determine.
        </p>
        <p>
          We will only use the personal information provided in your request to
          verify your identity or authority to make the request. We will try to
          avoid requesting any additional information for verification purposes.
          However, if we cannot verify your identity from the information we
          maintain, we may request for additional information for verification.
          Once we finish verifying you, we will delete any additional
          information you provided us for verification purposes.
        </p>
      </div>

      <div className="my-3">
        <p className="mb-2">Other privacy rights</p>
        <p>
          In addition to those rights listed above, you have the following
          privacy rights:
        </p>
        <ul className="list">
          <li className="bullet-item">
            You may object to the processing of your personal information
          </li>
          <li className="bullet-item">
            You may request correction of your personal data if incorrect or no
            longer relevant
          </li>
          <li className="bullet-item">
            You may ask to restrict the processing of your personal information
          </li>
          <li className="bullet-item">
            You may request to opt out from future selling or sharing of your
            personal information to third parties. Upon receiving an opt-out
            request, we will act upon the request in a timely manner and no
            later than fifteen (15) days from the date of verified request
            submission.
          </li>
        </ul>
        <p>
          To exercise these rights, you can contact us by email at{" "}
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a> or{" "}
          <a href="www.thesherlockcompany.com/contact">
            www.thesherlockcompany.com/contact
          </a>{" "}
          or refer to the contact details in this policy.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">
          For Virginia residents
        </h3>
        <p>
          As a Virginia resident, you may have specific rights about your
          personal information.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">Virginia CDPA privacy notice</h3>
        <p>
          The Virginia Consumer Data Protection Act (CDPA) defines the following
          terms:
        </p>
        <ul className="list">
          <li className="bullet-item">
            Consumer: a natural person who is a resident of the Commonwealth
            acting only in an individual or household context. It does not
            include a natural person acting in a commercial or employment
            context.
          </li>
          <li className="bullet-item">
            Personal data: any information that is linked or reasonably
            linkable to an identified or identifiable natural person. This does
            not include de-identified data or publicly available information.
          </li>
          <li className="bullet-item">
            Sale of personal data: the exchange of personal data for monetary
            consideration.
          </li>
        </ul>
        <p>
          If this definition of “consumer” applies to you, we have to adhere to
          certain rights and obligations regarding your personal data.
        </p>
      </div>

      <div className="my-3">
        <p className="mb-2">Your rights with respect to your personal data</p>
        <p>You have the right to:</p>
        <ul className="list">
          <li className="bullet-item">
            Be informed whether or not we are processing your personal data
          </li>
          <li className="bullet-item">Access your personal data</li>
          <li className="bullet-item">
            Correct inaccuracies in your personal data
          </li>
          <li className="bullet-item">Request deletion of your personal data</li>
          <li className="bullet-item">
            Obtain a copy of the personal data you previously shared with us
          </li>
          <li className="bullet-item">
            Opt out of the processing of your personal data if it is used for
            targeted advertising, sale of personal data, or profiling in
            furtherance of decisions that produce legal or similarly significant
            effects
          </li>
        </ul>
        <p>
          Holmes Moriarty, LLC has not sold any personal data to third parties
          for business or commercial purposes. Holmes Moriarty, LLC will not
          sell personal data in the future belonging to website visitors, users,
          and other consumers.
        </p>
      </div>

      <div className="my-3">
        <p className="mb-2">
          Exercise your rights provided under the Virginia CDPA
        </p>
        <p>
          More information about our data collection and sharing practices can
          be found in this privacy notice.
        </p>
        <p>
          You may contact us via{" "}
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>,{" "}
          <a href="www.thesherlockcompany.com/contact">
            www.thesherlockcompany.com/contact
          </a>
          , or by referring to the contact details in this policy.
        </p>
        <p>
          If you are using an authorized agent to exercise your rights, we may
          deny a request if the authorized agent does not submit proof that they
          have been validly authorized to act on your behalf.
        </p>
      </div>

      <div className="my-3">
        <p className="mb-2">Verification process</p>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine if you are the same person about whom the request is made.
          These verification efforts require us to ask you to provide
          information so that we can match it to the information you previously
          provided. We may use other verification methods as the circumstances
          determine.
        </p>
        <p>
          We will only use the personal information provided in your request to
          verify your identity or authority to make the request. We will try to
          avoid requesting any additional information for verification purposes.
          However, if we cannot verify your identity from the information we
          maintain, we may request additional information for verification. Once
          we finish verifying you, we will delete any additional information you
          provided us for verification purposes.
        </p>
        <p>
          Once your request is received, we will act upon the request within
          forty-five (45) days from the date of verified request submission. If
          additional time is needed, we will inform you of any extensions within
          the initial 45-day response period along with the reason for the
          extension.
        </p>
      </div>

      <div className="my-3">
        <p className="mb-2">Right to appeal</p>
        <p>
          If we decline to act on your request, we will inform you of our
          decision and the reason. If you wish to appeal the decision, please
          contact us via{" "}
          <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>. Upon
          receipt of this appeal request, we will inform you in writing of any
          action taken or not in response to the appeal within sixty (60) days.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">Do we make updates to this notice?</h3>
        <p>
          We will update this privacy notice periodically. The updated version
          will be indicated by an updated ‘revised’ date and will be effective
          on that date. We encourage you to review this privacy notice
          frequently to stay informed on how we are protecting your personal
          information.
        </p>
      </div>

      <div className="my-3">
        <h3 className="mb-2">How can you contact us about this notice?</h3>
        <p>
          You can contact our Data Protection Officer (DPO), Kyle Jones, if you
          have any questions or comments about this policy via email at{" "}
          <a href="mailto:kyle.jones@sherlockhm.com">
            kyle.jones@sherlockhm.com
          </a>{" "}
          or via postal mail at:
          <br /><br />
          Holmes Moriarty, LLC
          <br />
          Attn: Kyle Jones
          <br />
          401 Wilshire Blvd
          <br />
          12th Floor
          <br />
          Santa Monica, CA 90401
          <br />
          United States
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
