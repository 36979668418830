import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import sherlockLogo from "../images/sherlock-logo@4x.png";
import ImgSh from './Images/ImgSh';
import socII from "../images/soc2_footer_logo.png";
const Footer = () => {
  return (
    <MDBFooter className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on LinkedIn:</span>
        </div>
        <div>
          <a href='https://www.linkedin.com/company/thesherlockcompany' target="_blank" rel="noreferrer" className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='linkedin' />
          </a>
          {/* <Link to='/#' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='github' />
          </Link> */}
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>

              <ImgSh className="footerLogo" src={sherlockLogo} alt="The Sherlock Company Logo" width="503" height="173" />
              </h6>


              <h6 className='text-uppercase fw-bold mb-4'>

              </h6>
            </MDBCol>

            <MDBCol md='1' lg='1' xl='1' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Links</h6>
              <p>
                <Link to='/' className='text-reset'>
                  Home
                </Link>
              </p>
              <p>
                <Link to='/about' className='text-reset'>
                  About
                </Link>
              </p>
              <p>
                <Link to='/pipeline' className='text-reset'>
                  Pipeline
                </Link>
              </p>
              <p>
                <Link to='/services' className='text-reset'>
                  Services
                </Link>
              </p>
              <p>
                <Link to='/security' className='text-reset'>
                  Security
                </Link>
              </p>
              <p>
                <Link to='/contact' className='text-reset'>
                  Contact
                </Link>
              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Legal</h6>
              <p>
                <Link to='/tos' className='text-reset'>
                  Terms of Service
                </Link>
              </p>
              <p>
              <Link to='/DataPrivacy' className='text-reset'>
                Data Privacy
                </Link>
              </p>
              <p>
                <Link to='/bugbounty' className='text-reset'>
                  Responsible Disclosure
                </Link>
              </p>
              <p>
                <Link to='/privacy' className='text-reset'>
                  Privacy Policy
                </Link>
              </p>
              <p>
              <a href="https://www.aicpa.org/soc4so" target="_blank" rel="noreferrer">
              <ImgSh className="socIIlogo" src={socII} alt="SOCII Compliant" />
              </a>
              </p>

            </MDBCol>

            <MDBCol md='4' lg='4' xl='4' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
              <a href='https://us13.list-manage.com/contact-form?u=582977b975260743dc2371fac&form_id=cf00b3e6438e296c68210cea89328888' target="_blank" rel="noreferrer">
                  Reach out to Support
                </a>
              </p>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />
                401 Wilshire Blvd, 12th Floor, <br /> Santa Monica, CA 90401
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                <a href="mailto:info@sherlockhm.com">info@sherlockhm.com</a>
              </p>
              {/* <p>
                <MDBIcon color='secondary' icon='phone' className='me-3' /> <a href='tel:+1(818)480-8041'>+1 (818) 480-8041</a>
              </p> */}
              

            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        ©2024 The Sherlock Company. All Rights Reserved.
      </div>
    </MDBFooter>
  );
};

export default Footer;
