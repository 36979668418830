import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import ImgSh from '../../components/Images/ImgSh';
import "../../css/MovingShapes.scss";

const FullWidthImg = ({img, className}) => {
  return (
    <MDBContainer fluid className='p-0 m-0'>
        <MDBRow>
            <MDBCol md="12">
                <ImgSh src={img} alt="" className="img-fluid p-0 m-0" />
            </MDBCol>
        </MDBRow>
    </MDBContainer>
  )
}

export default FullWidthImg