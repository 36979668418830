import React, { useEffect, useState, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { motion } from 'framer-motion';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ImgSh = ({ src, alt, style, className, width, height, mouseEffectOn = false, threshold = 15, scaleFactor = 1.05 }) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const cardRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setLoading(false);
    img.onerror = () => {
      setLoading(false);
      setHasError(true);
    };
    img.src = src;
    img.width = width;
    img.height = height;
  }, [src, width, height]);

const handleMouseMove = (e) => {
  if (!mouseEffectOn || !cardRef.current) return;
  const { clientX, clientY } = e;
  const { offsetTop, offsetLeft, clientWidth, clientHeight } = cardRef.current;

  const horizontal = (clientX - offsetLeft) / clientWidth;
  const vertical = (clientY - offsetTop) / clientHeight;
  const rotateX = ((threshold - horizontal * threshold) * -1).toFixed(2); // Multiplied by -1 to invert
  const rotateY = ((vertical * threshold - threshold / 2) * -1).toFixed(2); // Multiplied by -1 to invert

  cardRef.current.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) translateZ(12px) scale(${scaleFactor})`;
  cardRef.current.style.filter = `drop-shadow(0px 10px 20px rgba(34, 34, 34, 0.2))`;
}

  const handleMouseLeave = () => {
    if (!mouseEffectOn || !cardRef.current) return;
    cardRef.current.style.transform = `perspective(${cardRef.current.clientWidth}px) rotateX(0deg) rotateY(0deg) translateZ(0px) scale(1)`;
    cardRef.current.style.filter = 'none';
  }
  // let placeholderWidth = 300
  // let placeholderHeight = 300
  // let placeholderImage = `https://placehold.co/300x300`;
  // if (width && height) {
  //   placeholderImage = `https://placehold.co/${placeholderWidth}x${placeholderHeight}`;
  // }
  
    // const actualSrc = hasError ? placeholderImage : src;
    const actualSrc = src;

  return (
    <motion.div
      ref={cardRef}
      onMouseMove={mouseEffectOn ? handleMouseMove : null}
      onMouseLeave={mouseEffectOn ? handleMouseLeave : null}
      style={
        mouseEffectOn && {transition: 'transform 0.2s ease-out, filter 0.2s ease-out', filter: 'none'}
      }
    >
      
      <LazyLoadImage
        src={actualSrc}
        alt={alt}
        effect="blur"
        wrapperClassName="image-wrapper"
        wrapperProps={{
          style: {
            display: loading ? 'block' : 'unset',
          },
        }}
        width={width}
        height={height}
        style={style}
        className={className}
        threshold={500}
      />
       {hasError && <img width={width} height={height} className={className} alt={alt} />}
    </motion.div>
  );
};

export default ImgSh;
