import React from "react";
import { Link } from "react-router-dom";

const TermsService = () => {
  return (
    <>
      <div className="container privacyPolicy">
        <h1 className="text-center my-5">Terms of Service</h1>
        <p>Last updated June 28, 2024</p>
        <div className="my-3">
          <p>
            We are Holmes-Moriarty, LLC doing business as The Sherlock Company,
            and our affiliates and subsidiaries (“The Sherlock Company,” “we,”
            “us,” or “our”), a company registered in California, United States
            at 401 Wilshire Blvd., 12th Floor, Santa Monica, CA 90401.
          </p>

          <p>
            We operate the website <Link to="/">https://www.thesherlockcompany.com </Link> and
            Sherlock Pipeline (the “Sites”), as well as other related products
            and services that refer or link to these legal terms (the “Legal
            Terms”) (collectively, the “Services”).
          </p>

          <p>
            You can contact us via email at <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a> or by mail to
            401 Wilshire Blvd., 12th Floor, Santa Monica, CA 90401, United
            States.
          </p>
          <p>
            These Legal Terms constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity (“you”),
            and Holmes Moriarty, LLC, concerning your access to and use of the
            Services. You agree that by accessing the Services, you have read,
            understood and agreed to be bound by all of these Legal Terms. IF
            YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
            EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.
          </p>
          <p>
            We reserve the right, in our sole discretion, to make changes to
            these Legal Terms from time to time. We will alert you about any
            changes by updating the “Last updated” date of these Legal Terms,
            and you waive any right to receive specific notice of each change.
            It is your responsibility to occasionally review these Legal Terms
            to stay informed of updates. You will be subject to and will be
            deemed to have been aware of and to have accepted the changes in any
            revised Legal Terms by your continued use of the Services after the
            date such revised Legal Terms are posted.
          </p>
          <p>
            The Services are intended for users who are at least 18 years old.
            Persons under the age of 18 are not permitted to use or register for
            the Services.
          </p>
        </div>
        <div className="my-3">
          <h3 className="mb-2">Our Services</h3>
          <p>
            The information provided when using the Services is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Services from other locations do so
            on their own initiative and are solely responsible for compliance
            with local laws, if and to the extend local laws are applicable.
          </p>
          {/* <p>
            The Services are not tailored to comply with industry-specific
            regulations (Health Insurance Portability and Accountability Act
            (HIPAA), Federal Information Security Management Act (FISMA), etc),
            so if your interactions would be subjected to such laws, you may not
            use the Services. You may not use the Services in a way that would
            violate the Gramm-Leach-Bliley Act (GLBA).
          </p> */}
        </div>
        <div className="my-3">
          <h3 className="mb-2">Intellectual Property Rights</h3>
          <p className="mb-2">Our intellectual property</p>
          <p>
            We are the owner or licensee of all intellectual property rights in
            our Services, including all source code, databases, functionality,
            software, website designs, audio, video, text, photographs, and
            graphics in the Services (collectively, the “Content”), as well as
            the trademarks, service marks, and logos contained therein (the
            “Marks”).
          </p>
          <p>
            Our Content and Marks are protected by copyright and trademark laws
            (and various other intellectual property rights and unfair
            competition laws) and treaties in the United States and around the
            world.
          </p>
          <p>
            The Content and Marks are provided in or through the Services “AS
            IS” for your personal, non-commercial use or internal business
            purpose only.
          </p>
          <p className="mb-2">Your use of our Services</p>
          <p>
            Subject to your compliance with these Legal Terms, including the
            Prohibited Activities section below, we grant you a non-exclusive,
            non-transferable, revocable license to:
          </p>
          <ul className="list">
            <li className="bullet-item">Access the Services; and</li>
            <li className="bullet-item">
              Download or print a copy of any portion of the final Content to
              which you have properly gained access solely for your personal,
              non-commercial use or internal business purpose.
            </li>
          </ul>
          <p>
            Except as set out in this section or elsewhere in our Legal Terms,
            no part of the Services nor Content or Marks may be copied,
            reproduced, aggregated, republished, uploaded, posted, publicly
            displayed, encoded, translated, transmitted, distributed, sold,
            licensed, or otherwise exploited for any commercial purpose
            whatsoever, without our express prior written permission.
          </p>
          <p>
            If you wish to make any use of the Services, Content or Marks other
            than as set out in this section or elsewhere in these Legal Terms,
            please address your request to <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a>. If we ever grant
            you the permission to post, reproduce, or publicly display any part
            of our Services or Content, you must identify us as the owners or
            licensors of the Services, Content or Marks and ensure that any
            copyright or proprietary notice appears or is visible on posting,
            reproducing, or displaying our Content.
          </p>
          <p>
            We reserve all rights not expressly granted to you in and to the
            Services, Content and Marks.
          </p>
          <p>
            Any breach of these Intellectual Property rights will constitute a
            material breach of our Legal Terms and your rights to use our
            Services will terminate immediately.
          </p>
          <p className="mb-2">Your submissions</p>
          <p>
            Please review this section and the Prohibited Activities section
            carefully prior to using our Services to understand the (a) rights
            you give us and (b) obligations you have when you post or upload any
            content through the Services.{" "}
          </p>
          <p>
            <strong>Submissions:</strong> By directly sending us any question,
            comment, suggestion, idea, feedback, or other information about the
            Services (“Submissions”), you agree to assign to us all intellectual
            property rights in such Submission. You agree that we shall own this
            Submission and be entitled to its unrestricted use and dissemination
            for any lawful purpose, commercial or otherwise, without
            acknowledgement or compensation to you.
          </p>
          <p>
            You are responsible for what you post or upload: By sending us
            Submissions through any part of the Services you:
          </p>
          <ul className="list">
            <li className="bullet-item">
              confirm that you have read and agree with our Prohibited
              Activities and will not post, send, publish, upload or transmit
              through the Services any Submission that is illegal, harassing,
              hateful, harmful, defamatory, obscene, bullying, abusive,
              discriminatory, threatening to any person or group, sexually
              explicit, false, inaccurate, deceitful, or misleading;
            </li>
            <li className="bullet-item">
              to the extend permissible by applicable law, waive any and all
              moral rights to any such Submissions;
            </li>
            <li className="bullet-item">
              warrant that any such Submission is original to you or that you
              have the necessary rights and licenses to submit such Submission
              and that you have full authority to grant us the above-mentioned
              rights in relation to your Submissions; and
            </li>
            <li className="bullet-item">
              warrant and represent that your Submissions do not constitute
              confidential information.
            </li>
          </ul>
          <p>
            You are solely responsible for all your Submissions, and you
            expressly agree to reimburse us for any and all losses that we may
            suffer because of your breach of (a) this section, (b) any third
            party’s intellectual property rights, or (c) applicable law.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">User Representations</h3>
          <p>
            By using the Services, you represent and warrant that: (1) you have
            the legal capacity and you agree to comply with these Legal Terms;
            (2) you are not a minor in the jurisdiction in which you reside; (3)
            you will not access the Services through automated or non-human
            means, whether through a bot, a script or otherwise; (4) you will
            not use the Services for any illegal or unauthorized purpose; and
            (5) your use of the Services will not violate any applicable law or
            regulation.
          </p>
          <p>
            If you provide any information that is untrue, inaccurate, no
            current or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Services (or any portion thereof).
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Prohibited Activities</h3>
          <p>
            You may not access or use the Services for any purpose other than
            that for which we make the Services available. The Services may not
            be used in connection with any commercial endeavours except those
            that are specifically endorsed or approved by us.
          </p>
          <p>
            As a user of the Services, you agree <strong>not to:</strong>
          </p>
          <ul className="list">
            <li className="bullet-item">
              Systematically retrieve data or other content from the Services to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us
            </li>
            <li className="bullet-item">
              Trick, defraud or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user
              passwords
            </li>
            <li className="bullet-item">
              Circumvent, disable, or otherwise interfere with security-related
              features of the Services, including features that prevent or
              restrict the use or copying of any Content or enforce limitations
              on the use of the Services and/or the Content contained therein
            </li>
            <li className="bullet-item">
              Disparage, tarnish or otherwise harm, in our opinion, us and/or
              the Services
            </li>
            <li className="bullet-item">
              Use any information obtained from the Services in order to harass,
              abuse or harm another person
            </li>
            <li className="bullet-item">
              Make improper use of our support services or submit false reports
              of abuse or misconduct
            </li>
            <li className="bullet-item">
              Use the Services in a manner inconsistent with any applicable laws
              or regulations
            </li>
            <li className="bullet-item">
              Engage in unauthorized framing of or linking to the Services
            </li>
            <li className="bullet-item">
              Upload or transmit (or attempt to) viruses, Trojan horses, or
              other material, including excessive use of capital letters and
              spamming (continuous posting of repetitive text), that interferes
              with any party’s uninterrupted use and enjoyment of the Services
              or modifies, impairs, disrupts, alters, or interferes with the
              use, features, functions, operation, or maintenance of the
              Services
            </li>
            <li className="bullet-item">
              Engage in any automated use of the system, such as using scripts
              to send comments or messages, or using any data mining, robots, or
              similar data gathering and extraction tools
            </li>
            <li className="bullet-item">
              Delete the copyright or other proprietary rights notice from any
              Content
            </li>
            <li className="bullet-item">
              Attempt to personate another user or person or use the username of
              another user
            </li>
            <li className="bullet-item">
              Upload or transmit (or attempt to upload or transmit) any material
              that acts as a passive or active information collection or
              transmission mechanism, including without limitation, clear gifs,
              1x1 pixels, web bugs, cookies, or other similar devices (sometimes
              referred to as ‘spyware’ or ‘passive collection mechanisms’ or
              ‘pcms’)
            </li>
            <li className="bullet-item">
              Interfere with, disrupt, or create an undue burden on the Services
              or the networks or services connected to the Services
            </li>
            <li className="bullet-item">
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Services to you
            </li>
            <li className="bullet-item">
              Attempt to bypass any measures of the Services designed to prevent
              or restrict access to the Services, or any portion of the Services
            </li>
            <li className="bullet-item">
              Copy or adapt the Services’ software, including but not limited to
              PHP, HTML, JavaScript, or other code
            </li>
            <li className="bullet-item">
              Except as permitted by applicable law, decipher, decompile,
              disassemble, or reverse engineer any of the software comprising or
              in any way making up a part of the Services
            </li>
            <li className="bullet-item">
              Except as may be the result of standard search engine or internet
              browser usage, use, launch, develop, or distribute any automated
              system, including without limitation, any spider, robot, cheat
              utility, scraper, or offline reader that accesses the Services, or
              use or launch of any unauthorized script or other software
            </li>
            <li className="bullet-item">
              Use a buying agent or purchasing agent to make purchases on the
              Services
            </li>
            <li className="bullet-item">
              Make any unauthorized use of the Services, including collecting
              usernames and/or email addresses of users by electronic or other
              means for the purpose of sending unsolicited email, or crating
              user accounts by automated means or under false pretenses
            </li>
            <li className="bullet-item">
              Use the Services as part of any effort to compete with us or
              otherwise use the Services and/or the Content for any
              revenue-generating endeavor or commercial enterprise
            </li>
            <li className="bullet-item">
              Use the Services to advertise or offer to sell goods and services
            </li>
            <li className="bullet-item">
              Sell or otherwise transfer your profile
            </li>
          </ul>
        </div>

        <div className="my-3">
          <h3 className="mb-2">User Generated Contributions</h3>
          <p>
            These Services do not offer users the ability to submit or post content. We may
            provide you with the opportunity to create, submit, post, display,
            transmit, perform, publish, distribute, or broadcast content and
            materials to use with the Services, including but not limited to
            text, writings, video, audio, graphics, comments, or other material
            (collectively, “Contributions”). Contributions may be viewable by
            other users of the Services. As such, any Contributions you transmit
            may be treated in accordance with the Services’ <Link to="/privacy">Privacy Policy</Link>. When
            you create or make available any Contributions, you thereby
            represent and warrant that:
          </p>
          <ul className="list">
            <li className="bullet-item">
              The creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party
            </li>
            <li className="bullet-item">
              You are the creator and owner of or have the necessary licenses,
              rights, consents, releases, and permissions to use and to
              authorize us, the Services, and other users of the Services to use
              your Contributions in any manner contemplated by the Services and
              these Legal Terms
            </li>
            <li className="bullet-item">
              You have the written consent, release and/or permission of each
              and every identifiable individual person in your Contributions to
              use the name or likeness of each and every such identifiable
              individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Services and these
              Legal Terms
            </li>
            <li className="bullet-item">
              Your Contributions are not false, inaccurate, or misleading
            </li>
            <li className="bullet-item">
              Your Contributions are not unsolicited or unauthorized
              advertising, promotional materials, pyramid schemes, chain
              letters, spam, mass mailings, or other forms of solicitation
            </li>
            <li className="bullet-item">
              Your Contributions are not obscene, lewd, lascivious, filthy,
              violent, harassing, libelous, slanderous or otherwise
              objectionable (as determined by The Sherlock Company)
            </li>
            <li className="bullet-item">
              Your Contributions do not ridicule, mock disparage, intimidate, or
              abuse anyone
            </li>
            <li className="bullet-item">
              Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of people
            </li>
            <li className="bullet-item">
              Your Contributions do not violate any applicable law, regulations
              or rule
            </li>
            <li className="bullet-item">
              Your Contributions do not violate the privacy or publicity rights
              of any third party
            </li>
            <li className="bullet-item">
              Your Contributions do not violate any applicable law concerning
              child pornography, and are not otherwise intended to harm the health or
              well-being of minors
            </li>
            <li className="bullet-item">
              Your Contributions do not include any offensive comments that are
              connected to race, national origin, gender, sexual preferences or
              physical handicap
            </li>
            <li className="bullet-item">
              Your Contributions do not otherwise violate, or link to material
              that violates, any provision of these Legal Terms, or any
              applicable law or regulation
            </li>
          </ul>
          <p>
            Any use of the Services in violation of the foregoing violates these
            Legal Terms and may result in, among other things, termination or
            suspension of your rights to use the Services.
          </p>
        </div>


        <div className="my-3">
        <h3 className="mb-2">Contribution License</h3>
          <p>
            You agree that we may access, store, process, and use
            any information and personal data that you provide following the
            terms of the <Link to="/privacy">Privacy Policy</Link> and your choices (including settings).
          </p>
          <p>
            By submitting suggestions or other feedback regarding the Services,
            you agree that we can use and share such feedback for any purposes
            without compensation to you.
          </p>
          <p>
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions to the Services, and you
            expressly agree to exonerate us from any and all responsibility and
            to refrain from any legal action against us regarding your
            Contributions.
          </p>
        </div>
        <div className="my-3">
          <h3 className="mb-2">Services Management</h3>
          <p>
            We reserve the right, but not the obligation to: (1) monitor the
            Services for violations of these Legal Terms; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Legal Terms, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to,
            limited availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Services or otherwise disable all files and content
            that are excessive in size or are in any way burdensome to our
            systems; and (5) otherwise manage the Services in a manner designed
            to protect our rights and property and to facilitate the proper
            functioning of the Services.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Privacy Policy</h3>
          <p>
            We care about data privacy and security. Please review our Privacy
            Policy: <Link to="/privacy">https://www.thesherlockcompany.com/privacy</Link> . By using the
            Services, you agree to be bound by our <Link to="/privacy">Privacy Policy</Link>, which is
            incorporated into these Legal Terms. Please be advised the Services
            are hosted in the United States. If you access the Services from any
            other region of the world with laws or other requirements governing
            personal data collection, use or disclosure that differ from
            applicable laws in the United States, then through your continued
            use of the Services, you are transferring your data to the United
            States, and you expressly consent to have your data transferred and
            processed in the United States.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Term and Termination</h3>
          <p>
            These Legal Terms shall remain in full force and effect while you
            use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
            LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
            REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
            ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
            TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SERVICES OR DELETE ANY CONTENT OR
            INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR
            SOLE DISCRETON.
          </p>
          <p>
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Modification and Interruptions</h3>
          <p>
            We reserve the right to change, modify, or remove the contents of
            the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Services. We will not be liable to you or any
            third party for any modification, price change, suspension or
            discontinuance of the Services.
          </p>
          <p>
            We cannot guarantee the Services will be available at all times. We
            may experience hardware, software, or other problems or need to
            perform maintenance related to the Services, resulting in
            interruptions, delays or errors. We reserve the right to change,
            revise, update, suspend, discontinue, or otherwise modify the
            Services at any time or for any reason without notice to you. You
            agree that we have no liability whatsoever for any loss, damage or
            inconvenience caused by your inability to access or us the Services
            during any downtime or discontinuance of the Services. Nothing in
            these Legal Terms will be construed to obligate us to maintain and
            support the Services or to supply any corrections, updates or
            releases in connection therewith.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Governing Law</h3>
          <p>
            These Legal Terms and your use of the Services are governed by and
            construed in accordance with the laws of the State of California
            applicable to agreements made and to be entirely performed within
            the State of California, without regard to its conflict of law
            principles.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Dispute Resolution</h3>
          <p className="mb-2">Binding Arbitration</p>
          <p>
            If the Parties are unable to resolve a Dispute through informal
            negotiations, the Dispute (except those Disputes expressly
            excluded below) will be finally and exclusively resolved by binding
            arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial
            Arbitration Rules of the <a href="https://www.adr.org" target="_blank">American Arbitration Association</a> (“AAA”)
            and, where appropriate, the AAA’s Supplementary Procedures for
            Consumer Related Disputes (“AAA Consumer Rules”), both of which are
            available at the American Arbitration Association (AAA) website.
            Your arbitration fees and your share of arbitrator compensation
            shall be governed by the AAA Consumer Rules and, where appropriate,
            limited by the AAA Consumer Rules. The arbitration may be conducted in person, through the
            submission of documents, by phone or online. The arbitrator will
            make a decision in writing but need not provide a statement of
            reasons unless requested by either Party. The arbitrator must follow
            applicable law, and any award may be challenged if the arbitrator
            fails to do so. Except where otherwise required by the applicable
            AAA rules or applicable law, the arbitration will take place in Los
            Angeles, California. Except as otherwise provided herein, the
            Parties may litigate in court to compel arbitration, stay
            proceedings pending arbitration, or to confirm, modify, vacate, or
            enter judgment on the award entered by the arbitrator.
          </p>
          <p>
            If for any reason, a Dispute proceeds in court rather than
            arbitration, the Dispute shall be commenced or prosecuted in the
            state and federal courts in Los Angeles, California, and the parties
            hereby consent to, and waive all defences of lack of personal
            jurisdiction, and forum non conveniens with respect to venue and
            jurisdiction in such state and federal courts. Application of the
            United Nations Convention on Contracts for the International Sale of
            Goods and the Uniform Computer Information Transaction Act (UCITA)
            are excluded from these Legal Terms.
          </p>
          <p className="mb-2">Restrictions</p>
          <p>
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class-action basis or to utilize class action
            procedures; and (c) there is no right or authority for any Dispute
            to be brought in a purported representative capacity on behalf of
            the general public or any other persons.
          </p>
          <p className="mb-2">Exceptions to Arbitration</p>
          <p>
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning binding arbitration: (a) any Dispute
            seeking to enforce or protect, or concerning the validity of, any of
            the intellectual property rights of a Party; (b) any Dispute related
            to, or arising from, allegations of theft, piracy, invasion of
            privacy, or unauthorized use; and (c) any claim for injunctive
            relief. If this provision is found to be illegal or unenforceable,
            then neither Party will elect to arbitrate any Dispute falling
            within that portion of this provision found to be illegal or
            unenforceable and such Dispute shall be decided by a court of
            competent jurisdiction within the courts listed for jurisdiction
            above, and the Parties agree to submit to the personal jurisdiction
            of that court.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Corrections</h3>
          <p>
            There may be information on the Services that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Services at any time, without prior
            notice.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Disclaimer</h3>
          <p>
            THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
            AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
            THE FULLEST EXTENT PERMITTIED BY LAW, WE DISCLAIM ALL WARRANTIES,
            EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
            THEREOF, INCUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SERVICES’ CONTENT OR THE CONTENT OF
            ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
            WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
            MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
            INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
            YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS
            TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
            INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
            INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,
            (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
            TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
            (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY
            LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
            CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT.
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Limitations of Liability</h3>
          <p>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
            TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE
            SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN
            US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
            IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
            DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
            DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
            ADDITIONAL RIGHTS.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Indemnification</h3>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of: (1)
            use of the Services; (2) breach of these Legal Terms; (3) any breach
            of your representations and warranties set forth in these Legal
            Terms; (4) your violation of the rights of a third party, including
            but not limited to intellectual property rights; or (5) any overt
            harmful act toward any other user of the Services with whom you
            connected via the Services. Notwithstanding the foregoing, we
            reserve the right, at your expense, to assume the exclusive defense
            and control of any matter for which you are required to indemnify
            us, and you agree to cooperate, at your expense, with our defense of
            such claims. We will use reasonable efforts to notify you of any
            such claim, action, or proceeding which is subject to this
            indemnification upon becoming aware of it.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">User Data</h3>
          <p>
            We will maintain certain data that you transmit to the Services for
            the purpose of managing the performance of the Services, as well as
            data relating to your use of the Services. Although we perform
            regular routine backups of data, you are solely responsible for all
            the data that you transmit or that relates to any activity you have
            undertaken using the Services. You agree that we shall have no
            liability to you for any loss or corruption of any such data, and
            you hereby waive any right of action against us arising from any
            such loss or corruption of such data.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">
            Electronic Communications, Transactions, and Signatures
          </h3>
          <p>
            Visiting the Services, sending us emails, and completing online
            forms constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Services, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SERVICES. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">California Users and Residents</h3>
          <p>
            If any complaint with us is not satisfactorily resolved, you can
            contact the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs in writing
            at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834 or by
            telephone at (800) 952-5210 or (916) 445-1254.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Miscellaneous</h3>
          <p>
            The Legal Terms and any policies or operating rules posted by us on
            the Services or in respect to the Services constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Legal Terms
            shall not operate as a waiver of such right or provision. These
            Legal Terms operate to the fullest extent permissible by law. We may
            assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Legal
            Terms is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Legal Terms and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Legal Terms or use of the Services. You agree that
            these Legal Terms will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defenses you may
            have based on the electronic form of these Legal Terms and the lack
            of signing by the parties hereto to execute these Legal Terms.
          </p>
        </div>

        <div className="my-3">
          <h3 className="mb-2">Contact Us</h3>
          <p>
            In order to resolve a complaint regarding the Services or to receive
            further information regarding use of the Services, please contact us
            at <a href="mailto:info@sherlockhm.com"> info@sherlockhm.com</a> or:
          </p>
          <p>
            Holmes Moriarty, LLC
            <br />
            401 Wilshire Blvd
            <br />
            12th Floor
            <br />
            Santa Monica, CA 90401
            <br />
            United States
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsService;
