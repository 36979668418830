import CreateDragDrop from "../../homePageSections/CreateDragDrop";
import ImgCol from "../columns/ImgCol";
import TextCopyBtnCol from "../columns/TextCopyBtnCol";

const ImageWithText = ({
  direction,
  image,
  title,
  titleLine2,
  textImage,
  copy,
  copyColor,
  maxWidth,
  btnText,
  btnColor,
  btnType,
  btnLink,
  titleHighlighted,
  titleColor,
  mdNumberTxt,
  mdNumberImg,
  imgClasses1,
  imgClasses2,
  includeDragDrop,
  html,
  mouseEffectOn,
  threshold,
  scaleFactor,
  padding,
  classes,
  imgLink,
  imgLinkExternal
}) => {
  const isTextRight = direction === "textRight";
  const classesForMobile = 'order-first order-md-last';

  const createComponents = (isTextRight) => {
    const textCopyBtnColProps = {
      title,
      titleLine2,
      textImage,
      titleHighlighted,
      titleColor,
      copy,
      copyColor,
      maxWidth,
      btnText,
      btnLink,
      mdNumberTxt,
      isTextFirst: !isTextRight,
      classes
    };
    const imgColProps = {
      image,
      mdNumberImg,
      MobileClasses: isTextRight ? classesForMobile : {},
      html,
      mouseEffectOn,
      threshold,
      scaleFactor,
      classes,
      imgLink,
      imgLinkExternal
    };

    return isTextRight
      ? (
        <>
          <TextCopyBtnCol {...textCopyBtnColProps} />
          {includeDragDrop ? <CreateDragDrop /> : <ImgCol {...imgColProps} />}
        </>
      )
      : (
        <>
          
          {includeDragDrop ? <CreateDragDrop /> : <ImgCol {...imgColProps} />}
          <TextCopyBtnCol {...textCopyBtnColProps} />
        </>
      );
  }

  return (
    <div className="container my-5 imageWithText">
      <div className="row gx-lg-5 pt-5">
        {createComponents(isTextRight)}
      </div>
    </div>
  );
};

export default ImageWithText;
