import React from 'react'

const TextItemP = ({copy, size, color}) => {
    
    switch (size) {
        case "small":
            return <p className="text-muted">{copy}</p>;
        case "med":
            return <p className="lead fw-normal text-muted">{copy}</p>;
        default:
            return <p style={{color: `${color}`}} className="">{copy}</p>;
    }

}

TextItemP.defaultProps = {
    copy: "This is a paragraph",
    size: "large",
    color: "rgb(134, 134, 134)"
}

export default TextItemP