
import {
  MDBContainer,
} from "mdb-react-ui-kit";
import ImageWithText from "../materialComponents/twoColumns/ImageWithText";
import { motion } from "framer-motion";
import ManageAnimation from "../homePageSections/ManageAnimation";
import servicesImg from "../images/Services_longscroll.webp";
import Analyze_SVG from "../images/Services_Analyze.svg";
import Analyze_IMG from "../images/Analyze_Image.png";
import mobile_img_1 from "../images/mobileImgs/create.jpg";
import mobile_img_2 from "../images/mobileImgs/localize.jpg";
import mobile_img_3 from "../images/mobileImgs/deliver.jpg";
import mobile_img_4 from "../images/mobileImgs/manage.jpg";
import "../css/ServicesPage.scss";
import Button from "../components/Button";
import ImgSh from "../components/Images/ImgSh";


const Servicespage = () => {



  const ManageData = [
    {
      id: 1,
      text: "localization.",
    },
    { 
      id: 2,
      text: "responsive ads.",
    },
    {
      id: 3,

      text: "automatic resizing.",
    },
    {
      id: 4,
      text: "product launches.",

    },
    {
      id: 5,
      text: "dubbing.",
    },
    {
      id: 6,
      text: "subtitling.",
    },
    {
      id: 7,
      text: "creative design.",
    },
  ];

  return (
    <motion.div
      // initial={{ opacity: 0 }}
      // animate={{  opacity: 1 }}
      // exit={{ opacity: 0, ease: "easeInOut" }}
      // transition={{
      //   duration: 0.5,
      //   ease: "easeInOut",
      // }}
  >
    
    <ManageAnimation title="Experts in" copyManage={1} ManageData={ManageData} />



    <div className="svg-container services container" style={{minHeight: "60vh"}}>
        <div className="txt1 floating-text-mobile">
        
        <ImgSh className="d-block mobile-show-only" src={mobile_img_1}/>
          <h2 className="txt-green">Create.</h2>
          <p>
          Whatever you need, we make it happen. 
          We become experts in your brand and can handle any project, from subtitling and dubbing to resizing keyart to creative executions.
          </p>
          <Button Text="Contact Us" btnLink="/contact" Type="tertiary" Color="#009193" />
        </div>

        <div className="txt2 floating-text-mobile">
        <ImgSh className="d-block mobile-show-only" src={mobile_img_2}/>
          <h2 className="txt-blue">Localize.</h2>
          <p>
          We offer translation services in over 300 languages and dialects, utilizing a network of 5,000 linguists and state-of-the-art AI and Translation Memory technology.
          </p>
          {/* <Button Text="Learn More" btnLink="/pipeline" Type="tertiary" Color="#314476" /> */}
        </div>

        <div className="txt3 floating-text-mobile">
        <ImgSh className="d-block mobile-show-only" src={mobile_img_3}/>
          <h2 className="txt-orange">Deliver.</h2>
          <p>
          All you have to do is approve the assets and we take care of the rest. Streamlined, on-time delivery, no matter how complex the requirements.
          </p>
          {/* <Button Text="Learn More" btnLink="/pipeline" Type="tertiary" Color="#FFA933" /> */}
        </div>

        <div className="txt4 floating-text-mobile">
        <ImgSh className="d-block mobile-show-only" src={mobile_img_4}/>
          <h2 className="txt-red">Manage.</h2>
          <p>
          We adapt our workflow to yours, so our teams can take on key areas of management for your projects—like brief creation, budgeting, even coordinating other vendors.
          </p>
          <Button Text="Learn More" btnLink="/pipeline" Type="tertiary" Color="#F27059" />
        </div>


        <motion.div
          style={{ zIndex: "-1", position: "relative" }}
        >
          <ImgSh
            className="services-content-svg mobile-no-show"
            src={servicesImg}
            // mouseEffectOn={true}
            // threshold={0}
            // scaleFactor={0.98}
            width={1344}
            height={2208}
          />
          
        </motion.div>
      </div>


    <MDBContainer className="pt-5" style={{marginBottom:-100, marginTop: 100}}>
      <ImgSh src={Analyze_SVG} alt="" />
    </MDBContainer>
    <div style={{marginBottom:100}}>
    <ImageWithText
      
      image={Analyze_IMG}
      direction="textLeft"
      mdNumberImg={8}
      mdNumberTxt={4}
      copy="Say goodbye to those 50-message-deep “Status?” email threads. We provide real-time insights about your projects, giving you actionable information to help improve efficiencies and make sure everything stays on track."
    ></ImageWithText>
    </div>
    {/* <MainFront
    content={servicesMainFrontContent}
    ></MainFront>
     */}
    {/* <MDBContainer>
      {servicesContentInfo.map((content) => (
        <ImageWithText key={content.id}
          image={content.image}
          direction={content.direction}
          title={content.title}
          copy={content.copy}
          btnText={content.btnText}
        ></ImageWithText>
      ))}
    </MDBContainer> */}
    

    </motion.div>
  )
}

export default Servicespage