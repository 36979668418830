
import React, { useEffect, Suspense, memo } from 'react';

import IFrame from '../components/Video/IFrame';
import useVideo from '../components/Video/useVideo';


const VideoContainer = ({videoData}) => {
  const { loadScript, loading, error } = useVideo();
  
  useEffect(() => {
    loadScript();
  }, [loadScript]);
  
  return (
    <div className="bg-white">
      <div className='Main-container container-fluid'>
        <section className='Video-container'>
          <div style={{padding: '75% 0 0 0', position: 'relative'}}>
            <Suspense fallback={<div>Loading...</div>}>
              {loading && <div>Loading video...</div>}
              {error && <div>Error: {error}</div>}
              {!loading && !error && 
                <IFrame 
                  src={videoData.src}
                  title="The Sherlock Company"
                />
              }
            </Suspense>
          </div>
        </section>
      </div>
    </div>
  );
}

export default memo(VideoContainer);
