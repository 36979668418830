import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


export default function ScrollToTop() {
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  // Scroll to top when pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Show/hide scroll to top button
  useEffect(() => {
    const checkScroll = () => {
      if (!isVisible && window.scrollY > 400){
        setIsVisible(true);
      } else if (isVisible && window.scrollY <= 400){
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, [isVisible]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    isVisible && (
      <div className="scroll-to-top cursor-pointer text-center" onClick={scrollToTop}>
        <p>↑</p>
      </div>
    )
  );
}
