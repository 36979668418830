import "./App.css";
import "./css/Main.scss";
import ScrollToTop from "./scrollToTop";

import "./fonts/Kollektif-Bold.ttf";
import "./fonts/Kollektif.ttf";
import "./fonts/Kollektif-Italic.ttf";
import "./fonts/Kollektif-BoldItalic.ttf";

import { BrowserRouter, ScrollRestoration } from "react-router-dom";
import { motion } from "framer-motion";

import Footer from "./components/Footer";

import MaterialNavbar from "./materialComponents/navbar/MaterialNavbar.js";
import AnimatedRoutes from "./components/AnimatedRoutes";
import { content } from "./content";
import {Cloudinary} from "@cloudinary/url-gen";

function App() {
  const cld = new Cloudinary({cloud: {cloudName: 'dritrgar4'}});
  return (
    <div className="App">
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          delay: 0.3,
          duration: 0.5,
        }}
      >
        <BrowserRouter>
          <ScrollToTop /> 
          <MaterialNavbar />
          
          <AnimatedRoutes content={content}></AnimatedRoutes>
          
          <Footer />
        </BrowserRouter>
      </motion.div>
    </div>
  );
}

export default App;
