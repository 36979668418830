import "../css/AboutPage.scss";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { motion } from "framer-motion";
import ImgSh from "../components/Images/ImgSh";
import { LazyLoadImage } from "react-lazy-load-image-component";
/**
 * Compares two strings in a natural order.
 *
 * @param {string} a - The first string to compare.
 * @param {string} b - The second string to compare.
 * @returns {number} - Returns a negative number if `a` should be sorted before `b`,
 *                     a positive number if `a` should be sorted after `b`,
 *                     or 0 if the strings are equal.
 */
const naturalCompare = (a, b) => {
  const ax = [],
    bx = [];

  a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
    ax.push([$1 || Infinity, $2 || ""]);
  });
  b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
    bx.push([$1 || Infinity, $2 || ""]);
  });

  while (ax.length && bx.length) {
    let an = ax.shift();
    let bn = bx.shift();
    let nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
    if (nn) return nn;
  }

  return ax.length - bx.length;
};

const importAllImages = (r) => {
  return r.keys().map(r).sort(naturalCompare);
};

const images = importAllImages(
  require.context("../images/IMAGES_FOR_SITE", false, /\.(png|jpe?g|svg)$/)
);

const Aboutpage = ({ content }) => {
  const aboutPage = content.pages.find((page) => page.id === "About");

  if (!aboutPage) {
    return <div>"About" page not found.</div>;
  }

  const { text } = aboutPage;
  return (
    <motion.div
      // initial={{ opacity: 0 }}
      // animate={{  opacity: 1 }}
      // exit={{ opacity: 0, ease: "easeInOut" }}
      // transition={{
      //   duration: 0.5,
      //   ease: "easeInOut",
      // }}
    >
      <MDBContainer fluid>
        <MDBContainer>
          <MDBRow>
            <div className="col-md-12" style={{minHeight: "300px"}}>
              {text.map((paragraph, index) => (
                <p className="aboutText" key={index}>
                  {paragraph}
                </p>
              ))}
            </div>
          </MDBRow>
        </MDBContainer>
        <MDBRow>
          <div className="photo-grid" style={{minHeight: "100vh"}}>
            {images.map((image, i) => (
              <div className="photo" key={i}>
                <img  src={image} alt={image} width={300} height={300} ></img>
              </div>
            ))}
          </div>
        </MDBRow>
      </MDBContainer>
    </motion.div>
  );
};

export default Aboutpage;
