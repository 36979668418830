import React, { useEffect, useState } from "react";
import { motion, useCycle } from "framer-motion";
import { Link } from "react-router-dom";

const MotionComponent = ({ animation, className, children }) => {
  return (
    <motion.h1
      animate={animation}
      initial={{ opacity: 0 }}
      className={className}
    >
      {children}
    </motion.h1>
  );
}

const HomepageMotionItem = React.memo(({ keyframes, item }) => {
  const [animation, cycle] = useCycle(...keyframes[item.id - 1]);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    let i = 0;
    const timeout = setTimeout(() => {
      setAnimating(true);
    }, 1000);
    const interval = setInterval(() => {
      if (animating) {
        cycle();
      }
      i++;
      if (i === 7) {
        i = 0;
      }
    }, 2200);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [animating, cycle, keyframes]);

  return (
    <MotionComponent animation={animation} className="item">
      <Link to="/pipeline">
        <motion.button>
          {item.text}
        </motion.button>
      </Link>
    </MotionComponent>
  );
});

const HomePageAnimatedTextItem = ({ item, keyframes }) => {
  return (
    <HomepageMotionItem
      keyframes={keyframes}
      item={item}
    />
  );
};

export default HomePageAnimatedTextItem;
