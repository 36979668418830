import "../css/Button.scss";
import PropTypes from "prop-types";
import { MDBBtn } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const Button = ({ Text, Color, Type, onClick, btnLink }) => {
  switch (Type) {
    case "primary":
      return (
        <Link to={btnLink}>
        <MDBBtn
          rippleColor="secondary"
          onClick={onClick}
          style={{ zIndex: 10 }}
          className={
            // [Color, Type].join(" ") +
            "btn-capitalize text-capitalize btn-lg mt-4 me-2 iso-class"
          }
        >
          {Text}
        </MDBBtn>
        </Link>
      );
      
    case "secondary":
      return (
        <Link to={btnLink}>
        <MDBBtn
          color='tertiary' rippleColor='primary'
          onClick={onClick}
          style={{ zIndex: 10 }}
          className={
            // [Color, Type].join(" ") +
            "btn-capitalize text-capitalize btn-lg mx-2 mt-4 px-4 iso-class"
          }
        >
          {Text}
        </MDBBtn>
        </Link>
      );

    case "tertiary":
      return (
        <Link to={btnLink}>
        <MDBBtn
           rippleColor='secondary'
          onClick={onClick}
          style={{ zIndex: 10, color:"#FFFFFF", backgroundColor:Color}}
          className={
            // [Color, Type].join(" ") +
            "btn-capitalize text-capitalize btn mt-4 px-4 iso-class"
          }
          
        >
          {Text}
        </MDBBtn>
        </Link>
      );
    default:
      return (
        <Link to={btnLink}>
        <MDBBtn
          rippleColor="secondary"
          onClick={onClick}
          style={{ zIndex: 10 }}
          className={
            // [Color, Type].join(" ") +
            "btn-capitalize text-capitalize btn-lg mt-4 me-2 iso-class"
          }
        >
          {Text}
        </MDBBtn>
        </Link>
      );
      
  }

};

Button.defaultProps = {
  Text: "Learn More",
  Color: "secondary",
  type: "primary",
};

Button.propTypes = {
  Text: PropTypes.string,
  Color: PropTypes.string,
  Type: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
