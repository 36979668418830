import { MDBContainer } from 'mdbreact';
import React, {  } from 'react';
import { } from 'framer-motion';
import ImgSh from './Images/ImgSh';


function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('../images/Sh_LOGOS', false, /\.(png|j2g|svg|webp|jpg)$/));

const ImageComponent = ({ image, i }) => {

  return (
    <div 
      className="logo-container"
    >
      
      <ImgSh 
        className="logoFarm" 
        src={image} 
        alt={`logo${i}`}
      />
    </div>
  );
};

const Logos = ({logoData}) => {

  return (
    <MDBContainer>
      <div className='logoFarmContainer'>
        <h2 className='logoFarmTitle'>{logoData.title}</h2>
        <div className='logo-rings-container'>
          {images.map((image, i) => 
            <ImageComponent key={i} image={image} i={i} />
          )}
        </div>
      </div>
    </MDBContainer>
  );
};

export default Logos;
