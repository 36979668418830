import React, { memo } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import placeHolder from '../../images/placeHolders/video.webp'

const IFrame = memo(({ src, title }) => (
  <LazyLoadComponent 
    threshold={100}
    placeholder={<img src={placeHolder} alt="video placeholder" />}
    visibleByDefault={true}
  >
    <iframe
      src={src}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      title={title}
    />
  </LazyLoadComponent>
));

export default IFrame;
