import React from 'react';

const TextItemH3 = ({title, titleHighlighted, titleColor}) => {
    const titleStyle = {
        color: titleColor,
    };
  
    return (
        <h3 className="display-4 fw-bold lh-1 ls-tight mb-2">
            <span style={titleStyle}>{title} {titleHighlighted && <br />}{" "}</span>
            {titleHighlighted && (
                <span className="text-primary">{titleHighlighted}</span>
            )}
        </h3>
    );
};

export default TextItemH3;
