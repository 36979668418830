import React from "react";
import { MDBCol } from "mdb-react-ui-kit";
import placeholder from "../../images/placeholder@4x.webp";
import ImgSh from "../../components/Images/ImgSh";
import { Link } from "react-router-dom";

const ImgCol = ({
  image = placeholder,
  mdNumberImg = "6",
  classes,
  MobileClasses,
  html,
  altText = "Image column",
  mouseEffectOn = false,
  threshold = 5,
  scaleFactor = 1.01,
  imgLink,
  imgLinkExternal
}) => {
  const content = html ? (
    <iframe
      src={`${process.env.PUBLIC_URL}/html_files/${html}.html`}
      width="100%"
      height="550px"
      className={`${html}-iframe`}
      title={`${html} iframe`}
    />
  ) : (
    <>
      {imgLinkExternal  ? (
        <a href={imgLink} target="_blank" rel="noreferrer">
         <ImgSh
        src={image}
        alt={altText}
        mouseEffectOn={mouseEffectOn}
        threshold={threshold}
        scaleFactor={scaleFactor}
        classNaame="mx-auto d-block"
      /></a>
        ): (
          
          <ImgSh
        src={image}
        alt={altText}
        mouseEffectOn={mouseEffectOn}
        threshold={threshold}
        scaleFactor={scaleFactor}
        classNaame="mx-auto d-block"
      />
        )
      
      }
      
      
    </>
  );

  return (
    <MDBCol
      md={mdNumberImg}
      sm="12"
      className={`mb-4 mb-md-0 position-relative mx-auto d-block align-items-center ${classes} ${MobileClasses}`}
    >
      {content}
    </MDBCol>
  );
};

export default ImgCol;