import ManageAnimation from "../homePageSections/ManageAnimation";
import VideoContainer from "../homePageSections/VideoContainer";
import PipelineSection from "../homePageSections/PipelineSection";

import { motion } from "framer-motion";
import "../css/HomepageSection.scss";
import "../css/AppHeader.scss";

import FullWidthImg from "../materialComponents/ImageContainers/FullWidthImg";
import MapImgSection from "../materialComponents/Sections/MapImgSection";
import ImageWithText from "../materialComponents/twoColumns/ImageWithText";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import Logos from "../components/Logos";
import CreateDragDrop from "../homePageSections/CreateDragDrop";

const Homepage = ({ content }) => {

  const homePage = content.pages.find((page) => page.id === "Homepage");
  const imageWithTextProps = [
    {
      section: homePage.projectManagement,
    },
    {
      section: homePage.dataManagement,
    },
    {
      section: homePage.globalSupport,
    },
  ];
  return (
    <motion.div
      // initial={{ opacity: 0 }}
      // animate={{  opacity: 1 }}
      // exit={{ opacity: 0, ease: "easeInOut" }}
      // transition={{
      //   duration: 0.5,
      //   ease: "easeInOut",
      // }}
    >
      <ManageAnimation
        faceImg={homePage.faceImg}
        title={homePage.manageAnimationTitle}
        hasCopy={true}
        ManageData={homePage.ManageData}
        copyManage={homePage.copyManage}
      />

      <VideoContainer videoData={homePage.videoData}  />

      {/* <CreateDragDrop mandoBg={mandoBg} mandoBgNoTT={mandoBgNoTT} image3={mandolanguage1} image4={mandolanguage2}/> */}
      
      <div className="bg-white">
        <MDBContainer>
          <Logos logoData={homePage.logoData}></Logos>
        </MDBContainer>
      </div>

      <MDBContainer>
        <MDBRow>
        <PipelineSection
        pipelineImg1={homePage.pipeline.pipelineImg1}
        pipelineImg2={homePage.pipeline.pipelineImg2}
        pipelineLogo={homePage.pipeline.pipelineLogo}
        createImg={homePage.pipeline.createImg}
        createImg2={homePage.pipeline.mandoBg}
        createImg3={homePage.pipeline.mandolanguage1}
        createImg4={homePage.pipeline.mandolanguage2}
        mandoBgNoTT={homePage.pipeline.mandoBgNoTT}
        adaptImg={homePage.pipeline.adaptImg}
        manageImg={homePage.pipeline.manageImg}
        btnLink="/pipeline"
      />
        </MDBRow>
      </MDBContainer>

      <div className="bg-white mb-5 pb-3">
      <MDBContainer>
        {imageWithTextProps.map(({ section }) => (
          <ImageWithText
            key={section.title}
            image={section.image}
            direction={section.direction}
            mdNumberImg={section.mdNumberImg}
            mdNumberTxt={section.mdNumberTxt}
            titleColor={section.titleColor}
            title={section.title}
            copy={section.copy}
            mouseEffectOn={section.mouseEffectOn}
            threshold={section.threshold}
            scaleFactor={section.scaleFactor}
          />
        ))}
      </MDBContainer>
      </div>

      

        <MapImgSection img={homePage.mapImg}></MapImgSection>


      <FullWidthImg img={homePage.presentationProductsImg}></FullWidthImg>
    </motion.div>
  );
};

export default Homepage;
