import React, { useEffect } from "react";
import ImgSh from "./Images/ImgSh";


export const Modal = ({ isOpen, close, imgSrc }) => {
  useEffect(() => {
    if (isOpen) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
      // Re-enable scrolling when component unmounts
      return () => (document.body.style.overflow = originalStyle);
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]); // Empty array ensures effect is only run on mount and unmount

  return isOpen ? (
    <div className="imageModal">
      <div>
        
        <ImgSh src={imgSrc} alt="Selected" style={{ maxWidth: "90%", maxHeight: "90%" }} />
        <button onClick={close} className="close-button" style={{ position: "absolute" }}>
          Close
        </button>
      </div>
    </div>
  ) : null;
};
