import React, { useState, useEffect } from "react";
import "../../css/MovingShapes.scss";
import pipelineLogo from "../../images/sherlock-pipeline-logo.png";
import computerImg from "../../images/ComputerPipelineScreen@4x.webp";
import TextCopyBtnCol from "../columns/TextCopyBtnCol";

const Shapes = () => {
  const shapeClasses = `
    rounded-circle
    position-absolute
    opacity-70
    scale-up-center
    d-none d-md-block
  `;

  return (
    <div className="shapes-container">
      <div id="shape-1" className={shapeClasses}></div>
      <div id="shape-2" className={`${shapeClasses} fade-delay-2000`}></div>
      <div id="shape-3" className={`${shapeClasses} fade-delay-4000`}></div>
    </div>
  );
};

const MovingShapes = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1068);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="container"
      style={{
        paddingBottom: 0,
        minHeight: "20vh",
        paddingTop: isSmallScreen ? 0 : 64,
      }}
    >
      <div className="row gx-lg-5">
        <TextCopyBtnCol
          title="Powered by"
          textImage={pipelineLogo}
          copy="Our software keeps every element of your project together, in one place, while helping us produce and deliver your assets. You can order new projects, manage spend, produce instant adaptations, track your work, and get automated delivery—all from your homepage. It’s your favorite software you’ve never heard of."
          mdNumberTxt="5"
          mdNumberImg="7"
          btnText="Request a Demo"
          btnLink="/contact"
        />
        <div className="col-md-7 mb-4 mb-md-0 position-relative d-flex align-items-center order-first order-md-last">
          <img src={computerImg} className="w-100" id="featured-image" alt="" />
          <Shapes />
        </div>
      </div>
    </div>
  );
};

export default MovingShapes;
