import React, { useEffect } from "react";
import Homepage from "../Pages/Homepage";
import Pipelinepage from "../Pages/Pipelinepage";
import Aboutpage from "../Pages/Aboutpage";
import Workpage from "../Pages/Workpage";
import Servicespage from "../Pages/Servicespage";
import Contactpage from "../Pages/Contactpage";
import Elementary from "../Pages/Elementary";
import BugBounty from "../Pages/Security/Bugbounty";
import DataPrivacy from "../Pages/Security/DataPrivacy";
import PrivacyPolicy from "../Pages/Security/PrivacyPolicy";
import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";
import ShowcaseItemPage from "../Pages/Articles/ShowcaseItemPage";
import TermsService from "../Pages/Security/TermsService";
import ReactGA from 'react-ga';

const trackingId = "G-7KSJ4YXRKC"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);


const routeTitles = {
  "/": "The Sherlock Company",
  "/Pipeline": "Pipeline | The Sherlock Company",
  "/About": "About | The Sherlock Company",
  "/Work": "Work | The Sherlock Company",
  "/Services": "Services | The Sherlock Company",
  "/Contact": "Contact Us | The Sherlock Company",
  "/Elementary": "Elementary | The Sherlock Company",
  "/Elementary/:id": "Article | The Sherlock Company",
  "/Bugbounty": "Responsible Disclosure Policy | The Sherlock Company",
  "/Security": "Security | The Sherlock Company",
  "/Privacy": "Privacy Policy | The Sherlock Company",
  "/Tos": "Terms of Service | The Sherlock Company",
  "/dataPrivacy": "Data Privacy | The Sherlock Company",

  // Add more routes and their titles as needed
  "/pipeline": "Pipeline | The Sherlock Company",
  "/about": "About | The Sherlock Company",
  "/work": "Work | The Sherlock Company",
  "/services": "Services | The Sherlock Company",
  "/contact": "Contact Us | The Sherlock Company",
  "/elementary": "Elementary | The Sherlock Company",
  "/elementary/:id": "Article | The Sherlock Company",
  "/bugbounty": "Responsible Disclosure Policy | The Sherlock Company",
  "/security": "Security | The Sherlock Company",
  "/privacy": "Privacy Policy | The Sherlock Company",
  "/tos": "Terms of Service | The Sherlock Company",
  "/DataPrivacy": "Data Privacy | The Sherlock Company",
  // Add more routes and their titles as needed
};

const AnimatedRoutes = ({ content }) => {
  const location = useLocation();

  useEffect(() => {
    // Trigger a Google Analytics pageview every time the route changes
    ReactGA.pageview(location.pathname + location.search);

    // Set document title based on current route
    const title = routeTitles[location.pathname] || "The Sherlock Company"; // Use "Default Title" or any other string as fallback
    document.title = title;

}, [location]);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Homepage  content={content}/>} />
        <Route path="pipeline" element={<Pipelinepage content={content}/>} />
        <Route path="about" element={<Aboutpage  content={content} />} />
        <Route path="work" element={<Workpage content={content}/>} />
        <Route path="services" element={<Servicespage content={content}/>} />
        <Route path="contact" element={<Contactpage content={content}/>} />
        <Route
          path="elementary/:id"
          element={<ShowcaseItemPage content={content} />}
        />
        <Route path="security" element={<Elementary content={content} />} />
        <Route path="bugbounty" element={<BugBounty content={content}/>} />
        <Route path="DataPrivacy" element={<DataPrivacy content={content}/>} />
        <Route path="privacy" element={<PrivacyPolicy content={content}/>} />
        <Route path="tos" element={<TermsService content={content}/>} />
        <Route path="/sherlock-react" element={<Homepage  content={content}/>} />
        <Route path="/*" element={<Homepage content={content} />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
