
import "../css/PipelineSection.scss";
import { MDBContainer } from "mdb-react-ui-kit";
import ImageWithText from "../materialComponents/twoColumns/ImageWithText";



const PipelineSection = (props) => {


  return (
    <MDBContainer fluid className="p-0 m-0">
      <section className="Pipeline-container section-container">



      <MDBContainer className="pt-3 md-3 mt-3">
        <ImageWithText
        image={props.pipelineImg1}
        textImage={props.pipelineLogo}
          copy="We take your assets and reproduce them to any size, language, style, or device–however you want it, we can get it done."
          mdNumberTxt="4"
          mdNumberImg="8"
          direction="textRight"
          btnText="See It in Action"
          btnLink={props.btnLink}
          mouseEffectOn={true}
      ></ImageWithText>
      </MDBContainer>

      </section>
    </MDBContainer>
  );
};

export default PipelineSection;
