import React from 'react'
import './TextItem.scss';

const TextItemH1 = ({title, titleHighlighted }) => {
  return (
    <h1 className="sh-h1 ">
          {title} {titleHighlighted && <br />}{" "}
          {titleHighlighted && (
            <span className="text-primary">{titleHighlighted}</span>
          )}
        </h1>
  )
}

export default TextItemH1