import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import ImgSh from '../../components/Images/ImgSh';

import "../../css/MapSection.scss";


const MapImgSection = ({img}) => {
  return (
    <div className="mapImgContainer">

    <MDBContainer>
      <MDBRow className='mt-5 pt-5 '>
        <MDBCol md="6">
        <h2>We’re worldwide.</h2>
        </MDBCol>
        <MDBCol md="6">
        <h3>And some of us just like working late!</h3>
        </MDBCol>
      </MDBRow>

        <MDBRow>
            <MDBCol className='pt-5'>
            
            
            <ImgSh src={img} alt=""  mouseEffectOn={true} threshold={1.3} scaleFactor={1.01} />

            </MDBCol>
        </MDBRow>

        <MDBRow className='mt-5'>
          <MDBCol>
          <h4 className='text-center mapDescription'>12 major cities • 24/7 global coverage • 150+ creative localization specialists •
300+ languages and dialects • 100,000+ perfectly-localized assets per month </h4>
          <p className='text-center pt-2'>Los Angeles, Phoenix, Houston, New York City, Jacksonville, São Paulo, London, Paris, Belgrade, Cairo, Tokyo, Sydney</p>
          </MDBCol>
        </MDBRow>
    </MDBContainer>
    
       
    </div>
  )
}

export default MapImgSection