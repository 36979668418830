import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Image from "../images/Pipeline_Main_img@4x.webp";

// Function to create a smooth, continuous motion with specific controls
function createSmoothMotion(xAmountSway, yAmountSway, scaleAmount, rotateXAmount, rotateYAmount, motionDuration = 12) {
  return {
    y: [0, -yAmountSway, 0, yAmountSway, 0],
    x: [0, -xAmountSway, 0, xAmountSway, 0],
    scaleY: [1, 1 + scaleAmount, 1, 1 - scaleAmount, 1], // Stretching and compressing
    rotateX: [0, rotateXAmount, 0, -rotateXAmount, 0],
    rotateY: [0, rotateYAmount, 0, -rotateYAmount, 0],
    transition: {
      duration: motionDuration, // Duration for smooth, slow movement
      ease: "easeInOut",
      repeat: Infinity,
    },
  };
}

const warpEffect = {
  filter: [
    "none",
    "url(#warpEffect)",
    "none",
    "url(#warpEffect)",
    "none"
  ],
  transition: {
    duration: 12,
    ease: "easeInOut",
    repeat: Infinity,
  },
};

export function PipelineFullscreenImg({
  scale,
  xAmountSway = 10,
  yAmountSway = 15,
  scaleAmount = 0.03,
  rotateXAmount = 2,
  rotateYAmount = 2,
  motionDuration = 12,
}) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    height: isSmallScreen ? "90%" : "150%", // Ensures the container takes up full height
    width: "100%", // Ensures the container takes up full width
    zIndex: 4,
    position: "relative",
    perspective: !isSmallScreen ? "1000px" : "none", // Disable perspective on mobile
  };
  
  const imageStyle = {
    scale: isSmallScreen ? 1 : scale,
    transformOrigin: "top center", // Stretching from the top
    willChange: "transform",
    objectFit: "contain", // Ensures the image fits within the container
    width: "100%", // Makes sure the image scales to the container width
    height: "100%", // Ensures the height fits within the container as well
  };

  const imageProps = {
    className: "pipeline-movies mobile-no-show",
    src: Image,
    alt: "pipeline",
  };

  return (
    <div style={containerStyle}>
      <svg style={{ position: "absolute", width: 0, height: 0 }}>
        <filter id="warpEffect">
          <feTurbulence type="fractalNoise" baseFrequency="0.02 0.02" numOctaves="2" result="warp" />
          <feDisplacementMap in="SourceGraphic" in2="warp" scale="10" />
        </filter>
      </svg>
      {!isSmallScreen && (
        <motion.div animate={warpEffect}>
          <motion.img
            {...imageProps}
            style={imageStyle}
            animate={createSmoothMotion(xAmountSway, yAmountSway, scaleAmount, rotateXAmount, rotateYAmount, motionDuration)}
          />
        </motion.div>
      )}
      {isSmallScreen && (
        <img
          {...imageProps}
          style={imageStyle}
        />
      )}
    </div>
  );
}
