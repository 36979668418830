import "../css/HomepageSection.scss";
import "../css/AbsoluteItemHomepage.scss";
import Faces from "./Faces";
import HomePageAnimatedTextItem from "../components/HomePageAnimatedTextItem";
import { motion } from "framer-motion";
import TextItemP from "../materialComponents/TextItems/TextItemP";
import Button from "../components/Button";
import { Link } from "react-router-dom";



const ManageAnimation = ({ title, ManageData, faceImg, hasCopy, copyManage }) => {

  const items = ManageData;
  const content = copyManage;

  var { positions, position7, position6, position5, position4, position3, position2, position1 } = CreatePositions();


  const keyframes = CreateKeyframesUp();

  function CreatePositions() {
    const position1 = 0;
    const position2 = 1;
    const position3 = 0.5;
    const position4 = 0.5;
    const position5 = 0.4;
    const position6 = 0.3;
    const position7 = 0;

    let positions = {
      position1: "0rem", 
    };

    for (let i = 1; i <= 7; i++) {
      positions[`down${5 * i}`] = `${5 * i}rem`;
      positions[`up${5 * i}`] = `-${5 * i}rem`;
    }
    return { positions, position7, position6, position5, position4, position3, position2, position1 };
  }

  function CreateKeyframesUp() {
    return [
      [
        { y: positions.down30, opacity: position7 },
        { y: positions.down25, opacity: position6 },
        { y: positions.down20, opacity: position5 },
        { y: positions.down15, opacity: position4 },
        { y: positions.down10, opacity: position3 },
        { y: positions.down5, opacity: position2 },

        { y: positions.position1, opacity: position1 },
      ],
      [
        //position 2
        { y: positions.up5, opacity: position1 },
        { y: positions.down25, opacity: position7 },
        { y: positions.down20, opacity: position6 },
        { y: positions.down15, opacity: position5 },
        { y: positions.down10, opacity: position4 },
        { y: positions.down5, opacity: position3 },

        { y: positions.position1, opacity: position2 },
      ],
      [
        //position 3
        { y: positions.up5, opacity: position2 },
        { y: positions.up10, opacity: position1 },
        { y: positions.down20, opacity: position7 },
        { y: positions.down15, opacity: position6 },
        { y: positions.down10, opacity: position5 },
        { y: positions.down5, opacity: position4 },

        { y: positions.position1, opacity: position3 },
      ],
      [
        { y: positions.up5, opacity: position3 },
        { y: positions.up10, opacity: position2 },
        { y: positions.up15, opacity: position1 },
        { y: positions.down15, opacity: position7 },
        { y: positions.down10, opacity: position6 },
        { y: positions.down5, opacity: position5 },

        { y: positions.position1, opacity: position4 },
      ],
      [
        { y: positions.up5, opacity: position4 },
        { y: positions.up10, opacity: position3 },
        { y: positions.up15, opacity: position2 },
        { y: positions.up20, opacity: position1 },
        { y: positions.down10, opacity: position7 },
        { y: positions.down5, opacity: position6 },

        { y: positions.position1, opacity: position5 },
      ],
      [
        { y: positions.up5, opacity: position5 },
        { y: positions.up10, opacity: position4 },
        { y: positions.up15, opacity: position3 },
        { y: positions.up20, opacity: position2 },
        { y: positions.up25, opacity: position1 },
        { y: positions.down5, opacity: position7 },

        { y: positions.position1, opacity: position6 },
      ],
      [
        { y: positions.up5, opacity: position6 },
        { y: positions.up10, opacity: position5 },
        { y: positions.up15, opacity: position4 },
        { y: positions.up20, opacity: position3 },
        { y: positions.up25, opacity: position2 },
        { y: positions.up30, opacity: position1 },

        { y: positions.position1, opacity: position7 },
      ],
    ];
  }


    return (
      <motion.div className="Main-container container">
      <section className="Section-one  justify-content-md-center">
        <motion.h1
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="title-static col-xs-auto p-0 position-relative"
        >
          {/* {copyExperts ?  : <div></div>} */}
          {title}&nbsp;
          
          {hasCopy && <div className="absolute-item homePageIntro"><TextItemP
          copy={content.subTitle}
          ></TextItemP>
          <Button
          Text="Contact Us"
          btnLink="/contact"
          ></Button>
          <Button
          Text="View Services"
          Type="secondary"
          btnLink="/services"
          ></Button></div>}
        
        </motion.h1>

        <div className="itemContainer col-xs-auto p-0">
          {items.map((item) => (
            <HomePageAnimatedTextItem
              key={item.id}
              item={item}
              keyframes={keyframes}
            ></HomePageAnimatedTextItem>
          ))}
        </div>
      </section>
      <Faces img={faceImg} />
    </motion.div>
    );
  


};

export default ManageAnimation;
