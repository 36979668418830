import React from 'react'
import { Link } from 'react-router-dom'

const DataPrivacy = () => {
  return (
    <div className="container privacyPolicy">
            
      <h1 className="text-center my-5">Data Privacy & Security Summary</h1>
      
      <div className="my-4">
        {/* <h3 className="mb-3">The Sherlock Company Information Security</h3> */}
        <p className="mb-3">The Sherlock Company has a strong commitment to information security that extends from the security team to senior levels of the organization. This is demonstrated by having implemented the following information security controls:</p>
        <ul className="list">
          <li className="bullet-item">All data are stored in the United States using Google Cloud Platform (GCP).</li>
          <li className="bullet-item">All data are encrypted at rest using AES-256 and in-transit using at least TLS 1.2.</li>
          <li className="bullet-item">All data are backed up daily/weekly/monthly, and The Sherlock Company Disaster Recovery Plan is tested/updated annually.</li>
          <li className="bullet-item">Production systems can only be remotely accessed by authorized employees possessing a valid multi-factor authentication (MFA) method.</li>
          <li className="bullet-item">The Sherlock Company requires authentication to production datastores to use authorized secure authentication mechanisms, such as unique SSH key.</li>
          <li className="bullet-item">The Sherlock Company prohibits confidential or sensitive customer data, by policy, from being used or stored in non-production systems/environments.</li>
          <li className="bullet-item">An infrastructure monitoring tool is utilized to monitor systems, infrastructure, and performance and generates alerts when specific predefined thresholds are met.</li>
          <li className="bullet-item">The Sherlock Company utilizes a log management tool to identify events that may have a potential impact on The Sherlock Company's ability to achieve its security objectives.</li>
          <li className="bullet-item">The Sherlock Company ensures that user access to in-scope system components is based on job role and function or requires a documented access request form and manager approval prior to access being provisioned.</li>
          <li className="bullet-item">The Sherlock Company has a mobile device management (MDM) system in place to centrally manage mobile devices supporting the service.</li>
          
          <li className="bullet-item">SOC II, Type 2 compliant since 2023. Read more <Link to='/security'>here</Link></li>
        </ul>
      </div>
    </div>
  )
}

export default DataPrivacy