import { useState, useCallback } from 'react';

const useVideo = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadScript = useCallback(() => {
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
    script.onload = () => setLoading(false);
    script.onerror = () => {
      setError('Failed to load video player');
      setLoading(false);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return {
    loadScript,
    loading,
    error,
  };
};

export default useVideo;
 