import React from "react";
import { MDBCol } from "mdb-react-ui-kit";
import Button from "../../components/Button";
import TextItemH3 from "../TextItems/TextItemH3";
import TextItemP from "../TextItems/TextItemP";
import ImgSh from "../../components/Images/ImgSh";
const TextCopyBtnCol = ({
  title,
  titleLine2,
  textImage,
  titleCobtnLinklor,
  copy,
  maxWidth,
  copyColor,
  btnText,
  btnLink,
  isBtn,
  isBtnSecondary,
  btnTextSecondary,
  btnLinkSecondary,
  classes,
  mdNumberTxt,
  titleHighlighted,
  titleColor,
  isTextFirst
}) => {
 
  return (
    <MDBCol
      md={mdNumberTxt}
      className={`d-flex align-items-center copy-col ${classes}`}
    >
      <div>
       
        {title && (textImage ? ( 
        <div>
        <TextItemP
          copy={title}
          color={titleColor}
        ></TextItemP>

        </div>
        
        ) : ( 
          <div>
          <TextItemH3
          title={title}
          titleColor={titleColor}
          titleHighlighted={titleHighlighted}
        ></TextItemH3>
          <TextItemH3
          title={titleLine2}
          titleColor={titleColor}
          titleHighlighted={titleHighlighted}
        ></TextItemH3>
        </div>

        ))}
        
        {textImage && (
          <ImgSh src={textImage} alt="textImage" className="mb-3"></ImgSh>
          
          )}
        {copy && (maxWidth ? (
          <div style={{maxWidth: `${maxWidth}px`}}>
          <TextItemP copy={copy} color={copyColor}></TextItemP>
          </div>
          
          ) : (
            <TextItemP copy={copy} color={copyColor}></TextItemP>
            
            )
        )}
        
        {btnText && 
          
        <Button Text={btnText} btnLink={btnLink}></Button>
        
        }

        {btnTextSecondary && (
          
          <Button Text={btnTextSecondary} Type="secondary" btnLink={btnLinkSecondary}></Button>
          
        )}
      </div>
    </MDBCol>
  );
};

TextCopyBtnCol.defaultProps = {
  copy: "secondary",
  classes: "mb-3 mb-md-0 d-flex align-items-center p-3",
  mdNumberTxt: "6",
  isBtn: false
};

export default TextCopyBtnCol;
