import React from "react";
import ElementaryTitle from "../images/Element/title.svg";
import TitleSVG from "../images/security/securityheader2.svg";
import mobileSVG from "../images/security/mobile_header.svg";
import certs from "../images/security/certifications.png";
import gpdImg from "../images/security/gdpr.svg";
import reqs from "../images/security/additional_Req.svg";
import mainBackground from "../images/security/main_bg.png";
import squiggle from "../images/Element/squiggle.svg";
import { motion } from "framer-motion";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import ImgSh from "../components/Images/ImgSh";
import TextItemH1 from "../materialComponents/TextItems/TextItemH1";
import ImageWithText from "../materialComponents/twoColumns/ImageWithText";
import TextCopyBtnCol from "../materialComponents/columns/TextCopyBtnCol";

// Object to store content

const Elementary = ({ content }) => {
  // find the "Elementary" page
  const elementaryPage = content.pages.find((page) => page.id === "Elementary");

  if (!elementaryPage) {
    return <div>"Elementary" page not found.</div>;
  }

  return (
    <div>
      <motion.div
      // initial={{ opacity: 0 }}
      // animate={{  opacity: 1 }}
      // exit={{ opacity: 0, ease: "easeInOut" }}
      // transition={{
      //   duration: 0.5,
      //   ease: "easeInOut",
      // }}
      >
        <MDBContainer
          style={{
            backgroundImage: `url(${mainBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            marginBottom: "-50px",
            paddingBottom: "50px",
          }}
        >
          <MDBRow className="m-5">
            <MDBCol lg="12" style={{ paddingBottom: "100px" }} className="mobile-padding-main">
              <Link to="/contact" style={{ textDecoration: "none" }}>
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{  opacity: 1 }}
                  transition={{
                    duration: 0.5,
                    ease: "easeInOut",
                  }}
                  src={TitleSVG}
                  alt="Elementary"
                  className="elementaryTitle mobile-no-show"
                  style={{ maxWidth: "100%", margin: "0 auto", padding: "5%" }}
                />
                <TextCopyBtnCol
                  classes={"mobile-show-only mobile_padding"}
                  textImage={mobileSVG}
                  copy="We’re serious about protecting our customers’ data. The Sherlock Company is SOC 2, Type II compliant, and our security team is constantly working on maintaining and meeting the latest security requirements. "
                  mdNumberTxt="12"
                  copyColor="#292929"
                  // title="Delivering On Security"
                ></TextCopyBtnCol>
                <TextCopyBtnCol
                  classes={"mobile-show-only pt-3 mobile_padding"}
                  copy="Have questions about our security and privacy practices? Send us a note and we’ll get in touch. "
                  btnText="Contact Us"
                  copyColor="#292929"
                  btnLink="/contact"
                ></TextCopyBtnCol>
              </Link>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="bg-white">
          <MDBContainer>
            <MDBRow className="bg-white">
              <MDBCol md="12">
                <div style={{ marginTop: "-3%" }}>
                  <ImageWithText
                    image={certs}
                    direction="textRight"
                    mdNumberImg={5}
                    mdNumberTxt={7}
                    title={"Our certifications"}
                    copyColor={"#292929"}
                    titleColor={"#292929"}
                    imgLink={"https://www.aicpa.org/soc4so"}
                    imgLinkExternal={true}
                    copy="SOC 2 Type II is a rigorous, globally recognized standard for managing and securing sensitive information. This certification means that we adhere to the highest standards in safeguarding against unauthorized access, ensuring confidentiality, integrity, and the availability of your valuable data. This framework is continually evaluated, meaning we’re committed to maintaining security best practices, keeping your data safe now and in the future."
                  ></ImageWithText>
                </div>
                <div style={{ marginTop: "-5%" }}>
                  <ImageWithText
                    image={gpdImg}
                    
                    mdNumberImg={3}
                    mdNumberTxt={9}
                    copyColor={"#292929"}
                    titleColor={"#292929"}
                    copy="We are also GDPR compliant, meaning that we adhere to strict regulations governing the collection, processing, and storage of personal data. With GDPR, you can trust that your data rights are respected, including the right to access, rectify, and erase your personal information. "
                  ></ImageWithText>
                </div>
              </MDBCol>
              <MDBCol md="12"></MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <MDBContainer>
          <div
            style={{
              backgroundImage: `url(${mainBackground})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              marginBottom: "-50px",
              paddingBottom: "50px",
            }}
          >
          <MDBRow className="mobile-no-show">
            <MDBCol md="12">
            <ImageWithText
                  image={reqs}
                  direction="textRight"
                  mdNumberImg={4}
                  mdNumberTxt={8}
                  copyColor={"#292929"}
                  titleColor={"#292929"}
                  classes={"mobile-no-show"}
                  title={"Additional requirements?"}
                  titleLine2={"No Problem."}
                  btnText={"Contact Us"}
                  btnLink={"/contact"}
                  copy="We know that security isn’t a one-size-fits-all requirement. If you have additional security requirements, we can work with your security team to find a solution. "
                  maxWidth={500}
                ></ImageWithText>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mobile-show-only">
              <MDBCol md="12">

                <ImageWithText
                  image={reqs}
                  direction="textRight"
                  mdNumberImg={4}
                  mdNumberTxt={8}
                  copyColor={"#292929"}
                  titleColor={"#292929"}
                  classes={"mobile-show-only"}
                  title={"Additional requirements? No Problem."}
                  btnText={"Contact Us"}
                  btnLink={"/contact"}
                  copy="We know that security isn’t a one-size-fits-all requirement. If you have additional security requirements, we can work with your security team to find a solution.  "
                  maxWidth={500}
                ></ImageWithText>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBContainer>
      </motion.div>
    </div>
  );
};

export default Elementary;
