import { useEffect, useState } from 'react';
import { motion, useScroll, useTransform } from "framer-motion";

const Faces = ({ img }) => {
  // Get scrollYProgress from useViewportScroll hook
  const { scrollYProgress } = useScroll();

  // Create a motion value that transforms scrollYProgress to a range of movement for the parallax effect.
  const parallaxY = useTransform(scrollYProgress, [0, 0.1], [100, 0]);

  // Create a motion value that transforms scrollYProgress to a scale value
  const scale = useTransform(scrollYProgress, [0, 0.2], [1.2, 1]);

  // Create a state to keep track of window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Function to update window width state
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Use useEffect to listen for window resize events
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // Check if window width is less than 764px
  const isSmallScreen = windowWidth < 768;

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        delay: 0.3,
        duration: 1,
      }}
    >
      <div className="Full-container faces">
        <motion.img 
          src={img} 
          alt=""
          style={{ y: isSmallScreen ? 0 : parallaxY, scale: isSmallScreen ? 1 : scale }} // Disable animation on small screens
        />
      </div>
    </motion.div>
  );
};

export default Faces;
