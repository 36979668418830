import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ResizableBox } from "react-resizable";
import "../css/DragDrop.scss";
import Button from "../components/Button";

const constraintsByBreakpoint = {
  image1: {
    xs: { drag: { top: -30, left: -225, right: 15, bottom: 100 }, resize: { min: [54, 22], max: [219, 88] } },
    sm: { drag: { top: -45, left: -337, right: 22, bottom: 150 }, resize: { min: [82, 33], max: [328, 132] } },
    md: { drag: { top: -52, left: -393, right: 26, bottom: 175 }, resize: { min: [98, 39], max: [393, 158] } },
    lg: { drag: { top: -60, left: -450, right: 30, bottom: 200 }, resize: { min: [109, 44], max: [438, 176] } }
  },
  image2: {
    xs: { drag: { top: -100, left: -225, right: 15, bottom: 25 }, resize: { min: [54, 22], max: [219, 88] } },
    sm: { drag: { top: -150, left: -337, right: 22, bottom: 37 }, resize: { min: [82, 33], max: [328, 132] } },
    md: { drag: { top: -175, left: -393, right: 26, bottom: 43 }, resize: { min: [98, 39], max: [393, 158] } },
    lg: { drag: { top: -200, left: -450, right: 30, bottom: 50 }, resize: { min: [109, 44], max: [438, 176] } }
  }
};

const CreateDragDrop = ({ mandoBg, mandoBgNoTT, image3, image4 }) => {
  const [shouldDrag1BeEnabled, setDrag1Enable] = useState(false);
  const [shouldDrag2BeEnabled, setDrag2Enable] = useState(false);
  const [showTT, setShowTT] = useState(true);
  const [constraints, setConstraints] = useState([
    constraintsByBreakpoint.image1.lg,
    constraintsByBreakpoint.image2.lg
  ]);

  let toggleText = "Hide the original english";
  let btnToggleType = "primary";
  const toggle = React.useCallback(
    () => {
      setShowTT(!showTT)
    },
    [showTT, setShowTT, toggleText, btnToggleType],
  );

  if (showTT) {
    toggleText = "Hide the original english title"
    btnToggleType = "primary"
  } else {
    toggleText="Show the original english title"
    btnToggleType = "secondary"
  }

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      let breakpoint;

      if (width < 576) { // xs
        breakpoint = 'xs';
      } else if (width < 768) { // sm
        breakpoint = 'sm';
      } else if (width < 992) { // md
        breakpoint = 'md';
      } else { // lg and xl
        breakpoint = 'lg';
      }

      setConstraints([
        constraintsByBreakpoint.image1[breakpoint],
        constraintsByBreakpoint.image2[breakpoint]
      ]);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the function initially to set the constraints based on the initial viewport width

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="createImgContainer overflow-visible "
      style={{ backgroundImage: `url(${showTT ? mandoBg : mandoBgNoTT})` }}
    >
      <div className="btn-above">
        <div className="text-container">
          <Button
            Text={toggleText}
            onClick={toggle}
          ></Button>
        </div>
      </div>
      <div className="absolute-pos titleMove">
        <motion.div
          drag
          dragMomentum={false}
          dragConstraints={constraints[0].drag}
          whileDrag={{ opacity: 0.8, scale: 1 }}
          whileFocus={{ zIndex: 2 }}
          dragElastic={0.1}
          dragListener={shouldDrag1BeEnabled}
          whileHover={{ scale: 1.05 }}
          alt=""
        >
          <ResizableBox
            width={219}
            height={88}
            minConstraints={constraints[0].resize.min}
            maxConstraints={constraints[0].resize.max}
            lockAspectRatio={true}
          >
            <motion.img
              className="never-selector"
              src={image3}
              onHoverStart={() => {
                if (!shouldDrag1BeEnabled) {
                  setDrag1Enable(!shouldDrag1BeEnabled);
                }
              }}
              onHoverEnd={() => {
                if (shouldDrag1BeEnabled) {
                  setDrag1Enable(!shouldDrag1BeEnabled);
                }
              }}
            />
          </ResizableBox>
        </motion.div>
        <motion.div
          drag
          dragMomentum={false}
          dragConstraints={constraints[1].drag}
          whileDrag={{ opacity: 0.8, scale: 1}}
          whileFocus={{ zIndex: 2 }}
          dragElastic={0.1}
          dragListener={shouldDrag2BeEnabled}
          whileHover={{ scale: 1.05 }}
          alt=""
        >
          <ResizableBox
            width={219}
            height={88}
            minConstraints={constraints[1].resize.min}
            maxConstraints={constraints[1].resize.max}
            lockAspectRatio={true}
          >
            <motion.img
              className="never-selector"
              src={image4}
              onHoverStart={() => {
                if (!shouldDrag2BeEnabled) {
                  setDrag2Enable(!shouldDrag2BeEnabled);
                }
              }}
              onHoverEnd={() => {
                if (shouldDrag2BeEnabled) {
                  setDrag2Enable(!shouldDrag2BeEnabled);
                }
              }}
            />
          </ResizableBox>
        </motion.div>
      </div>
    </div>
  );
};

export default CreateDragDrop;
